import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
  NEWSLETTER_PATH,
  NEWSLETTER_URL,
  SUBSCRIPTIONS_PATH,
  SUBSCRIPTIONS_URL,
} from "../services/TdConstants";
import { TdAccountSubscriptions } from "../services/tdSubscription/useTdAccountSubscriptions";
import { TdAuthState } from "../services/tdAuth/useTdAuthState";
import { tdUpdateClassName } from "../services/tdUpdateClassName";
import TdAuthModalTrigger from "./TdAuth/TdAuthModalTrigger";
import { TdWindowLeave } from "./TdWindowLeave";
import SvgNewsletter from "./ui/Svg/SvgMail";
import TdButton from "./ui/TdButton/TdButton";
import TdLinkButton from "./ui/TdButton/TdLinkButton";
import { tdClass, tdClassNames } from "./ui/tdClass";
import TdInfo from "./ui/TdInfo/TdInfo";
import TdLink from "./ui/TdLink/TdLink";
import TdModal from "./ui/TdModal/TdModal";
import TdModalFooter from "./ui/TdModal/TdModalFooter";
import TdModalMain from "./ui/TdModal/TdModalMain";
import TdModalTitle from "./ui/TdModal/TdModalTitle";

interface TdAdsProps extends TdAuthState {
  subscriptions: TdAccountSubscriptions;
  checksum: number;
}

const doc = document;
const adsClass = tdClass("ad");
const hasAdsClass = adsClass + "--show";
const noAdsClass = adsClass + "--hide";
const initAdsClass = adsClass + "--init";
const readyAdsClass = adsClass + "--ready";

const addModalIdCookie = "td-ad-modal";
const addModalTime: number = 1800; // 3600 * 18 // 18 hours

function doIgnoreModal(cookieValue: number) {
  const currentPath = window.location ? window.location.pathname : "";
  let ignoreModal = cookieValue
    ? Date.now() - cookieValue < addModalTime * 1000
    : false;
  if (currentPath && !ignoreModal) {
    if (currentPath) {
      [SUBSCRIPTIONS_PATH, NEWSLETTER_PATH].forEach((path) => {
        if (currentPath.indexOf(path) === 0) {
          ignoreModal = true;
        }
      });
    }
  }
  return ignoreModal;
}

export function TdAds({ authUid, authReady, subscriptions }: TdAdsProps) {
  const [showAds, setShowAds] = useState<boolean>(false);
  const [cookies, setCookie] = useCookies([addModalIdCookie]);
  const [modalStatus, setModalStatus] = useState<"init" | "open" | "dismissed">(
    doIgnoreModal(cookies[addModalIdCookie]) ? "dismissed" : "init",
  );

  const handleModalDismiss = () => {
    if (modalStatus === "open") {
      setModalStatus("dismissed");
    }
  };
  const handleLeave = () => {
    if (modalStatus === "init") {
      setModalStatus("open");
      setCookie(addModalIdCookie, Date.now(), {
        maxAge: addModalTime,
      });
    }
  };
  const notifyAdService = (showAds: boolean) => {
    if (window) {
      // Actirise
      // @ts-ignore
      window._hbdbrk = window._hbdbrk || [];
      // @ts-ignore
      window._hbdbrk.push([
        "_vars",
        {
          no_pub: !showAds,
        },
      ]);
      console.log("_hbdbrk - no_pub:", !showAds);

      // Publift
      if (!showAds) {
        const fuseTag:
          | { que: Array<() => void>; resetSlots: () => void }
          | undefined =
          // @ts-ignore
          window.fusetag;
        if (fuseTag) {
          // console.log('fuseTag - resetSlots');
          fuseTag.que.push(() => {
            fuseTag.resetSlots();
          });
        }
      }
    }
  };

  useEffect(() => {
    const allReady = authReady
      ? !!authUid
        ? !!subscriptions.resolved
        : true
      : false;
    if (allReady) {
      tdUpdateClassName(doc.body, initAdsClass, readyAdsClass, readyAdsClass);
      if (subscriptions.active.length) {
        // hide ads
        setShowAds(false);
        notifyAdService(false);
        tdUpdateClassName(doc.body, hasAdsClass, noAdsClass);
        if (!doIgnoreModal(cookies[addModalIdCookie])) {
          setModalStatus("init");
        }
      } else {
        // show ads
        setShowAds(true);
        notifyAdService(true);
        tdUpdateClassName(doc.body, noAdsClass, hasAdsClass);
      }
    }
  }, [authReady, subscriptions]);

  return showAds ? (
    <>
      {modalStatus === "init" ? <TdWindowLeave onLeave={handleLeave} /> : null}
      <TdModal
        id={"td-ad-free"}
        triggerShow={modalStatus === "open" ? 1 : 0}
        triggerHide={modalStatus === "open" ? 0 : 1}
        onHide={() => handleModalDismiss()}
        noScroll={true}
      >
        <TdModalMain>
          <TdModalTitle>
            Prefer to read without ads? <span>Subscribe to The Diplomat.</span>
          </TdModalTitle>
          <TdInfo
          // icon={<IconPercent />}
          >
            <b>Save 20% for a whole year!</b>
            <p>
              Use the coupon code{" "}
              <b className={tdClassNames(["text-promo-code"])}>ADFREE20</b> at
              checkout to get a 20% discount for the first year of your
              subscription.
            </p>
            <TdLink href={SUBSCRIPTIONS_URL}>Redeem coupon now</TdLink>
          </TdInfo>
          <TdLinkButton
            href={SUBSCRIPTIONS_URL}
            target={"_blank"}
            className={tdClassNames(["mt-1", "width--full"])}
          >
            Subscribe Now
          </TdLinkButton>
          <TdButton
            look={"ghost"}
            className={tdClassNames(["mt-1", "width--full"])}
            onClick={handleModalDismiss}
          >
            Maybe later
          </TdButton>
          {!authUid ? (
            <div
              className={tdClassNames(["mt-1", "width--full", "text-center"])}
            >
              Already have an account?{" "}
              <TdAuthModalTrigger onClick={handleModalDismiss}>
                <TdLink>Sign in here</TdLink>
              </TdAuthModalTrigger>
            </div>
          ) : null}
        </TdModalMain>
        <TdModalFooter>
          <div className={tdClassNames(["flex-row"])}>
            <div>
              <SvgNewsletter size={48} />
            </div>
            <div className={tdClassNames(["pl-3"])}>
              <b>Free Email Newsletter</b>
              <br />
              <b>
                <TdLink href={NEWSLETTER_URL}>Sign up</TdLink>
              </b>{" "}
              for a weekly brief on Asia-Pacific's most important headlines.
            </div>
          </div>

          {/*  <TdModalTitle>Get our free Email Newsletter <span>to be briefed on the Asia-Pacific's most important headlines</span></TdModalTitle>*/}
          {/*  <TdLinkButton*/}
          {/*    href={NEWSLETTER_URL}*/}
          {/*    target={"_blank"}*/}
          {/*    className={tdClassNames(["mt-1", "width--full"])}*/}
          {/*  >*/}
          {/*    Get the Newsletter*/}
          {/*  </TdLinkButton>*/}
          {/*  <TdButton*/}
          {/*    look={"ghost"}*/}
          {/*    className={tdClassNames(["mt-1", "width--full"])}*/}
          {/*    onClick={handleModalDismiss}*/}
          {/*  >*/}
          {/*    Maybe later*/}
          {/*  </TdButton>*/}
        </TdModalFooter>
      </TdModal>
    </>
  ) : null;
}
