import { useEffect, useRef, useState } from "react";
import { TdAuthUid } from "../tdAuth/useTdAuthState";
import { TdSubscriptionId } from "./tdSubscription";

import {
  listenToSubscription
} from "./tdSubscriptionFirebase";

export type TdAccountSubscriptions = {
  active: TdSubscriptionId[];
  data: {
    [planId: string]: SubscriptionsStateItem;
  };
  watching: number;
  resolved: number;
  checksum: number;
  ready: boolean;
};

type SubscriptionsStateItem = {
  valid: boolean;
  expires: number;
  renews: boolean;
};

function blankSubscriptionState(plans: string[]): TdAccountSubscriptions {
  return {
    active: [],
    data: {},
    watching: plans ? plans.length : 0,
    resolved: 0,
    checksum: 0,
    ready: false, // !(plans && plans.length),
  };
}

export function useTdAccountSubscriptions(
  uid: TdAuthUid,
  plans: string[],
  full?: boolean,
) {
  const uidRef = useRef<TdAuthUid>(null);
  const listenersRef = useRef<Function[]>([]);
  // const subscriptionsRef = useRef<TdAccountSubscriptions>(
  //   blankSubscriptionState(plans),
  // );

  const [subscriptions, setSubscriptions] = useState<TdAccountSubscriptions>(
    blankSubscriptionState(plans),
  );
  const clearListeners = () => {
    if (listenersRef.current.length) {
      listenersRef.current.forEach((fn) => fn());
      listenersRef.current = [];
    }
  };

  const updateSubscription = (
    planId: string,
    planData: SubscriptionsStateItem,
  ) => {
    setSubscriptions((prevState) => {
      let newSubscriptions = blankSubscriptionState(plans);
      Object.keys(prevState.data).forEach((k) => {
        newSubscriptions.data[k] = {
          ...prevState.data[k],
        };
      });
      newSubscriptions.data[planId] = planData;
      Object.keys(newSubscriptions.data).forEach((k) => {
        const planData = newSubscriptions.data[k];
        if (planData.valid) {
          newSubscriptions.active.push(k as TdSubscriptionId);
          newSubscriptions.checksum += 1;
        }
        newSubscriptions.resolved += 1;
        newSubscriptions.checksum += planData.expires || 0;
      });
      newSubscriptions.ready = !!(
        newSubscriptions.resolved &&
        newSubscriptions.watching === newSubscriptions.resolved
      );
      return newSubscriptions;
    });
  };

  useEffect(() => {
    if (
      uid !== uidRef.current ||
      (plans.length && !listenersRef.current.length)
    ) {
      clearListeners();
      uidRef.current = uid;
      if (uid) {
        plans.forEach((plan) => {
          listenersRef.current.push(
            listenToSubscription(
              uid,
              plan,
              (changed_plan, _exists, isValid, expires, renews) =>
                updateSubscription(changed_plan, {
                  valid: isValid,
                  expires: expires,
                  renews: !!renews,
                }),
              full,
            ),
          );
        });
      } else {
        setSubscriptions(blankSubscriptionState([]));
      }
    }
    return () => clearListeners();
  }, [uid]);

  return [subscriptions];
}
