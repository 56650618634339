import classNames from "classnames";
import "./tdModal.css";
import { tdModalClassName } from "./TdModal";
import { JSX } from "react";

type TdModalTitleProps = {
  className?: string;
  children: string | JSX.Element | Array<string | JSX.Element | null>;
};

export default function TdModalTitle({
  className,
  children,
}: TdModalTitleProps) {
  return (
    <div
      className={classNames(tdModalClassName + "-title", className)}
      children={children}
    />
  );
}
