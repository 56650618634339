export function tdUpdateClassName(
  element: HTMLElement,
  removeClass?: string,
  addClass?: string,
  checkClass?: string,
): void {
  if (element && (removeClass || addClass)) {
    if (element.classList) {
      const proceed = !(checkClass && element.classList.contains(checkClass));
      if (proceed) {
        if (addClass) {
          element.classList.add(addClass);
        }
        if (removeClass) {
          element.classList.remove(removeClass);
        }
      }
    } else {
      let classes = element.className ? element.className.split(" ") : [];
      if (removeClass && classes.length) {
        const i = classes.indexOf(removeClass);
        if (i >= 0) {
          classes.splice(i, 1);
        }
      }
      if (addClass) {
        classes.push(addClass);
      }
      element.className = classes.join(" ");
    }
  }
}
