import {
  ACCOUNT_URL,
  CLICK_HERE,
  MAILTO_SUPPORT_URL
} from "../../services/TdConstants";
import TdAccountModalContent from "../TdAccount/TdAccountModalContent";

import TdButton from "../ui/TdButton/TdButton";
import TdLinkButton from "../ui/TdButton/TdLinkButton";
import { tdClass, tdClassNames } from "../ui/tdClass";
import TdAccountModalFooter from "../TdAccount/TdAccountModalFooter";
import TdAuthModalFooterPw from "./TdAuthModalFooterPw";
import TdAuthSignOut from "./TdAuthSignOut";
import TdAuthForm from "./TdAuthForm";
import TdLink from "../ui/TdLink/TdLink";
import TdModalMain from "../ui/TdModal/TdModalMain";
import TdModalFooter from "../ui/TdModal/TdModalFooter";
import TdModalTitle from "../ui/TdModal/TdModalTitle";
import { TdAuthState } from "../../services/tdAuth/useTdAuthState";

export interface TdAuthModalContentProps extends TdAuthState {
  hideModal: Function;
}

export default function TdAuthModalContent(
  {
    authUid,
    authEmail,
    authSignedIn,
    hideModal,
  }: TdAuthModalContentProps
) {
  return (
    <>
      {!!authSignedIn ? (
        <>
          <TdModalMain>
            <TdModalTitle>
              Welcome back <span>You are signed in as {authEmail}</span>
            </TdModalTitle>
            {/*<TdInfo icon={<SvgUser />} className={tdClass("m--v2")}>*/}
            {/*  <strong>Welcome back</strong>*/}
            {/*  <p>*/}
            {/*    You are signed in as '<b>{authEmail}</b>'.*/}
            {/*  </p>*/}
            {/*</TdInfo>*/}
            <TdAccountModalContent uid={authUid || null} />

            <TdLinkButton
              href={ACCOUNT_URL}
              target={"_blank"}
              className={tdClassNames(["mt-1", "width--full"])}
            >
              Manage your Account
            </TdLinkButton>
            <TdAuthSignOut onSuccess={hideModal}>
              <TdButton
                look={"ghost"}
                className={tdClassNames(["mt-1", "width--full"])}
              >
                Sign Out
              </TdButton>
            </TdAuthSignOut>
          </TdModalMain>
          <TdModalFooter>
            <TdAccountModalFooter />
          </TdModalFooter>
        </>
      ) : (
        <>
          <TdModalMain>
            <TdModalTitle>Sign in</TdModalTitle>
            <TdAuthForm type={"login"} onSuccess={hideModal} />
          </TdModalMain>
          <TdModalFooter>
            <ul className={tdClass("ul")}>
              <TdAuthModalFooterPw />
              <li>
                <b>
                  Do you have any questions regarding your account or
                  subscription?
                </b>
                <br />
                <TdLink href={MAILTO_SUPPORT_URL}>{CLICK_HERE}</TdLink> to send
                us an email.
              </li>
            </ul>
          </TdModalFooter>
        </>
      )}
    </>
  );
}
