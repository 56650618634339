import "./TdCheckout.css";
import React, { useEffect, useState } from "react";
import { TdAuthState } from "../../services/tdAuth/useTdAuthState";
import { tdCheckoutRequestSessionUrl } from "../../services/tdCheckout/tdCheckoutSession";
import type { TdCheckoutRequestSessionUrlResponse } from "../../services/tdCheckout/tdCheckoutSession";
import { MAILTO_SUPPORT_URL, SUPPORT_EMAIL } from "../../services/TdConstants";

import {
  CURRENCY_CODES,
  CurrencyCode,
  isValidCurrencyCode,
  validatedCurrencyCode,
} from "../../services/tdCurrency";
import {
  aliasToPlan,
  getBillingPeriods,
  getSubscriptionPriceId,
  getSubscriptionPlanName,
  getSubscriptionPlanPeriods,
  planToAlias,
  TdSubscriptionBillingPeriod,
  TdSubscriptionId,
  TdSubscriptionPeriod,
  TXT_Subscription,
  getSubscriptionPlanPrice,
  TXT_Student,
} from "../../services/tdSubscription/tdSubscription";
import { TdAccountSubscriptions } from "../../services/tdSubscription/useTdAccountSubscriptions";
import { joinWithSpace } from "../../services/tdText";
import TdAuthForm from "../TdAuth/TdAuthForm";
import TdAuthSignOut from "../TdAuth/TdAuthSignOut";
import SvgErrorCircle from "../ui/Svg/SvgErrorCircle";
import SvgInfo from "../ui/Svg/SvgInfo";
import SvgUser from "../ui/Svg/SvgUser";
import TdButton from "../ui/TdButton/TdButton";
import { tdClass } from "../ui/tdClass";
import TdFormActions from "../ui/TdForm/TdFormActions";
import TdInfo from "../ui/TdInfo/TdInfo";
import TdLink from "../ui/TdLink/TdLink";
import TdSection from "../ui/TdSection/TdSection";
import { TdCheckoutOption, TdCheckoutOptions } from "./TdCheckoutOption";
import { TdCheckoutStudentCheck } from "./TdCheckoutStudentCheck";

type paramsObject = { [key: string]: string };

function getSearchParams(): paramsObject {
  let obj: paramsObject = {};
  let queryString = document.location.search
    ? document.location.search.slice(1)
    : "";
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split("#")[0];
    // split our query string into its component parts
    const arr = queryString.split("&");
    for (let i = 0; i < arr.length; i++) {
      // separate the keys and the values
      const a = arr[i].split("=");
      if (a[0] && a[1]) {
        obj[a[0]] = a[1];
      }
    }
  }
  return obj;
}

const _planKey = "plan";
const _currencyKey = "currency";

const TXT_year = "year";
const TXT_month = "month";
export const TXT_checkout = "checkout";

export const TdCheckoutClassName = tdClass(TXT_checkout);

const periods = getBillingPeriods();

export interface TdCheckoutProps extends TdAuthState {
  plans: TdSubscriptionId[];
  subscriptions: TdAccountSubscriptions;
  path: string;
  isRenew?: boolean;
  isStudent?: boolean;
}

const searchParamKey = _planKey;

export function TdCheckout({
  authUid,
  authEmail,
  plans,
  path,
  isRenew,
  isStudent,
}: TdCheckoutProps) {
  const searchParams = getSearchParams();

  const [isNewUser, setIsNewUser] = useState<boolean>(!isRenew);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSent, setIsSent] = useState<boolean>(false);
  const [hasStudentCheckError, setHasStudentCheckError] =
    useState<boolean>(false);
  const [errorInfo, setErrorInfo] = useState<
    TdCheckoutRequestSessionUrlResponse["error"] | null
  >(null);

  const [selectedPlan, setSelectedPlan] = useState<TdSubscriptionId>(
    aliasToPlan(searchParams[searchParamKey], plans),
  );
  const [selectedPeriod, setSelectedPeriod] =
    useState<TdSubscriptionBillingPeriod>(periods[0]);
  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyCode>(
    validatedCurrencyCode(searchParams[_currencyKey], "USD"),
  );

  const selectedPlanPeriods = getSubscriptionPlanPeriods(
    selectedPlan,
    selectedCurrency,
  );

  useEffect(() => {
    let periodExists = false;
    let _selectedPlanPeriods = getSubscriptionPlanPeriods(
      selectedPlan,
      selectedCurrency,
    );
    _selectedPlanPeriods.forEach((po) => {
      if (po.id === selectedPeriod) {
        periodExists = true;
      }
    });
    if (!periodExists) {
      if (!_selectedPlanPeriods.length) {
        console.error("no prices for", selectedPlan); // see: tdSubscriptionData -> price.yr.id etc.
        setSelectedPeriod("yr");
      } else {
        setSelectedPeriod(_selectedPlanPeriods[0].id);
      }
    }
  }, [selectedPlan]);
  const onStudentCheck = (e: boolean) => {
    setHasStudentCheckError(e);
  };

  const sendForm = () => {
    setIsSent(true);
    setErrorInfo(null);
    if (!hasStudentCheckError) {
      const priceId = getSubscriptionPriceId(selectedPlan, selectedPeriod);
      if (authUid && priceId) {
        setIsLoading(true);
        tdCheckoutRequestSessionUrl({
          priceId: priceId,
          successUrl: path + "/success/",
          cancelUrl:
            path + "?" + searchParamKey + "=" + planToAlias(selectedPlan),
          allowPromotionCodes: true,
          currency: selectedCurrency,
        })
          .then((response) => {
            if (response.url) {
              window.location.href = response.url;
            }
            if (response.error) {
              console.error(response.error);
              setErrorInfo(response.error);
            }
            setIsLoading(false);
          })
          .catch((e) => {
            console.error(e);
            setIsLoading(false);
          });
      }
    }
  };

  return (
    <div className={TdCheckoutClassName}>
      <TdSection step={1} title={"Choose your " + TXT_Subscription}>
        <h3>Choose a Plan</h3>
        <TdCheckoutOptions
          id={TdCheckoutClassName + "-plans"}
          // className={tdClass("mt-2")}
        >
          {plans.map((pk) => (
            <TdCheckoutOption
              key={pk}
              name={_planKey}
              value={pk}
              checked={selectedPlan}
              onPlan={setSelectedPlan}
              children={
                <>
                  <b>
                    {getSubscriptionPlanName(pk, true)}{" "}
                    <span>{TXT_Subscription}</span>
                  </b>
                  {/*<b>{TXT_Subscription}</b>*/}
                  <p>
                    {selectedCurrency}{" "}
                    {getSubscriptionPlanPrice(
                      pk,
                      selectedPeriod,
                      selectedCurrency,
                    )}{" "}
                    /&nbsp;year
                  </p>
                </>
              }
            />
          ))}
        </TdCheckoutOptions>
        <h3>Choose a Currency</h3>

        {/*<div style={{marginBottom: '-8px'}}>*/}
        {/*  <b>Choose a currency</b>*/}
        {/*</div>*/}
        <TdCheckoutOptions id={TdCheckoutClassName + "-" + _currencyKey}>
          {CURRENCY_CODES.map((currencyCode) => (
            <TdCheckoutOption
              key={currencyCode}
              name={_currencyKey}
              value={currencyCode}
              checked={selectedCurrency}
              onPlan={setSelectedCurrency}
              // size={"sm"}
              children={<b>{currencyCode}</b>}
            />
          ))}
        </TdCheckoutOptions>
        {/*
        <h3>Choose a Billing Cycle</h3>
        <TdCheckoutOptions>
          {selectedPlanPeriods.map((periodOption, i) => (
            <TdCheckoutOption
              key={periodOption.id}
              name={'period'}
              value={periodOption.id}
              checked={selectedPeriod}
              onPlan={setSelectedPeriod}
              children={
                <>
                  <b>{periodOption.id === "yr" ? "Annual" : "Monthly"}</b>
                  <div>
                    <b className={TdCheckoutClassName + "-price"}>
                      { selectedCurrency + ' ' + periodOption.amount }
                    </b>
                    <span>
                      {joinWithSpace([
                        " per",
                        periodOption.id === "yr" ? TXT_year : TXT_month,
                      ])}
                    </span>
                  </div>
                </>
              }
            />
          ))}
        </TdCheckoutOptions>
         */}
      </TdSection>
      <TdSection
        step={2}
        title={
          (authUid ? "Y" : isNewUser ? "Create y" : "Sign-In to y") +
          "our Account"
        }
      >
        {authUid ? (
          <TdInfo icon={<SvgUser />}>
            <b>You are signed in as '{authEmail}'</b>
            <p>
              Not you?{" "}
              <TdLink>
                <TdAuthSignOut>Sign out</TdAuthSignOut>
              </TdLink>
              .
            </p>
          </TdInfo>
        ) : (
          <>
            <TdInfo icon={<SvgUser />} onClick={() => setIsNewUser(!isNewUser)}>
              {isNewUser ? (
                <>
                  Already have an account? <TdLink>Sign in</TdLink>.
                </>
              ) : (
                <>
                  New to <i>The Diplomat</i>? <TdLink>Create an account</TdLink>
                  .
                </>
              )}{" "}
            </TdInfo>
            <TdAuthForm
              type={isNewUser ? "create" : "login"}
              className={tdClass("mb-1")}
            />
          </>
        )}
      </TdSection>
      {isStudent ? (
        <TdCheckoutStudentCheck isSent={isSent} setError={onStudentCheck} />
      ) : null}
      <TdSection step={3 + (isStudent ? 1 : 0)} title={"Review and Pay"}>
        {TdCheckoutSummary(
          selectedPlanPeriods,
          selectedPeriod,
          selectedCurrency,
        )}
        <TdFormActions>
          {!authUid ? (
            <TdInfo
              look={isSent ? "error" : undefined}
              icon={isSent ? <SvgErrorCircle /> : <SvgInfo />}
            >
              {isNewUser ? (
                <p>
                  Please <b>create your account</b> or{" "}
                  <label htmlFor="td-account-modal_trigger">
                    <TdLink
                      className={isSent ? tdClass("text-white") : undefined}
                      children={"sign in"}
                    />
                  </label>{" "}
                  at step 2 before proceeding.
                </p>
              ) : (
                <p>
                  Please{" "}
                  <b>
                    <label htmlFor="td-account-modal_trigger">
                      <TdLink
                        className={isSent ? tdClass("text-white") : undefined}
                        children={"sign in"}
                      />
                    </label>
                  </b>{" "}
                  or create your account at step 2 before proceeding.
                </p>
              )}
            </TdInfo>
          ) : null}
          <TdButton
            disabled={isLoading || !authUid}
            loading={isLoading}
            onClick={sendForm}
          >
            {isLoading ? "Processing..." : "Proceed to Payment"}
            {/*{isLoading ? "Processing..." : "Pay by Credit Card"}*/}
          </TdButton>
          {/*<p><b>Credit Cards: </b>Visa, Mastercard, American Express, Cartes Bancaires and JCB.</p>*/}
          {/*<p><b>Wallets: </b>Apple Pay, Google Pay, Link</p>*/}

          {/*<TdButton*/}
          {/*  look={"ghost"}*/}
          {/*  disabled={isLoading || !authUid}*/}
          {/*  loading={isLoading}*/}
          {/*  onClick={sendForm}*/}
          {/*>*/}
          {/*  Pay by PayPal*/}
          {/*</TdButton>*/}
          {/*<TdButton*/}
          {/*  look={"ghost"}*/}
          {/*  disabled={isLoading || !authUid}*/}
          {/*  loading={isLoading}*/}
          {/*  onClick={sendForm}*/}
          {/*>*/}
          {/*  Pay by Bank Transfer*/}
          {/*</TdButton>*/}
        </TdFormActions>
        {errorInfo ? (
          errorInfo.alias === "stripe-customer-currency" ? (
            <TdInfo look={"error"} icon={<SvgErrorCircle />}>
              <b>Unable to proceed with {selectedCurrency}</b>
              <p>
                You already have an active subscription in another currency
                {isValidCurrencyCode(errorInfo.info)
                  ? " (" + errorInfo.info + ")"
                  : ""}
                .<br />
                Multiple currencies for a single customer are not supported at
                this time.
                <br />
                <b>
                  Please consider paying in{" "}
                  {validatedCurrencyCode(errorInfo.info, "USD")}
                </b>
                {". "}
                Or <TdLink
                  href={MAILTO_SUPPORT_URL}
                  children={"contact us"}
                />{" "}
                to change the currency of all your subscriptions.
              </p>
            </TdInfo>
          ) : null
        ) : null}
      </TdSection>
    </div>
  );
}

function TdCheckoutSummary(
  selectedPlanPeriods: TdSubscriptionPeriod[],
  selectedPeriod: TdSubscriptionBillingPeriod,
  selectedCurrency: CurrencyCode,
) {
  return selectedPlanPeriods.map((periodOption) =>
    periodOption.id === selectedPeriod ? (
      <div
        key={periodOption.id}
        className={"tda-checkout-summary tda-flex-row"}
      >
        <div>
          <b>
            {periodOption.name} (1{" "}
            {selectedPeriod === "yr" ? TXT_year : TXT_month})
          </b>
          <div>{periodOption.desc}</div>
        </div>
        <div>
          <b>
            {selectedCurrency} {periodOption.amount}
          </b>
          <div>
            {joinWithSpace([
              " per",
              selectedPeriod === "yr" ? TXT_year : TXT_month,
            ])}
          </div>
        </div>
      </div>
    ) : null,
  );
}
