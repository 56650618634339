import "./TdFormInput.css";
import { tdClass } from "../tdClass";
import type { ComponentPropsWithoutRef } from "react";

export interface TdFormInputProps extends ComponentPropsWithoutRef<"input"> {}
export const TdFormInputClassName = tdClass("input");

function TdFormInput({ type, ...inputProps }: TdFormInputProps) {
  return (
    <input
      className={TdFormInputClassName}
      type={type ? type : "text"}
      autoComplete="off"
      {...inputProps}
    />
  );
}

export default TdFormInput;
