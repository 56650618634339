interface TdDateProps {
  timestamp: number;
}
export default function TdDate({ timestamp }: TdDateProps) {
  const date = new Date(timestamp);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <time>
      {monthNames[date.getMonth()] +
        " " +
        date.getDate() +
        ", " +
        date.getFullYear()}
    </time>
  );
}
