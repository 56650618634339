import React, { useState } from "react";
import TdModalDomController from "../ui/TdModal/TdModalDomController";

import TdAuthModalContent, {
  TdAuthModalContentProps,
} from "./TdAuthModalContent";

interface TdAuthModalProps extends Omit<TdAuthModalContentProps, "hideModal"> {

}

export const TD_AUTH_MODAL_ID = "td-account-modal";

export default function TdAuthModal(props: TdAuthModalProps) {
  const [hide, setHide] = useState(0);
  const hideModal = () => setHide(Date.now());

  return (
    <TdModalDomController
      id={TD_AUTH_MODAL_ID}
      triggerHide={hide}
      children={<TdAuthModalContent {...props} hideModal={hideModal} />}
    />
  );
}
