import "./TdButton.css";

import type { ComponentPropsWithoutRef } from "react";
import { TdButtonBaseProps, tdButtonClassNames } from "./TdButton";

interface TdLinkButtonProps
  extends TdButtonBaseProps,
    ComponentPropsWithoutRef<"a"> {}

export default function TdLinkButton({
  className,
  look,
  loading,
  ...props
}: TdLinkButtonProps) {
  return (
    <a {...props} className={tdButtonClassNames(className, look, loading)} />
  );
}
