import SvgWrapper, { SvgWrapperProps } from "./SvgWrapper";
import React from "react";

function SvgUser(props: SvgWrapperProps) {
  return (
    <SvgWrapper {...props}>
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </SvgWrapper>
  );
}

export default SvgUser;

/* 32px
<path d="M16,8a5,5,0,1,0,5,5A5,5,0,0,0,16,8Z" />
<path d="M16,2A14,14,0,1,0,30,16,14.0158,14.0158,0,0,0,16,2Zm7.9925,22.9258A5.0016,5.0016,0,0,0,19,20H13a5.0016,5.0016,0,0,0-4.9925,4.9258,12,12,0,1,1,15.985,0Z" />
*/
