import { tdDomGetElementById } from "./tdDomGetElementById";
import { tdDomGetElementDataAttribute } from "./tdDomGetElementDataAttribute";

type dataType = "text/plain" | "text/html" | "application/json";

export function tdDomRead(id: string, expectedType: dataType): string | null {
  let response = null;
  if (id) {
    const el = tdDomGetElementById(id);
    if (el) {
      let innerHTML = el.innerHTML;
      const dataType =
        tdDomGetElementDataAttribute(el, "type") || el.getAttribute("type");
      if (dataType === expectedType) {
        response = innerHTML;
      }
    } else {
      console.log(id + " not found");
    }
  }
  return response;
}
