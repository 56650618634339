import "./TdButton.css";
import classNames from "classnames";
import type { ComponentPropsWithoutRef } from "react";
import { tdClass } from "../tdClass";

export interface TdButtonBaseProps {
  className?: string;
  look?: "ghost" | "accent";
  loading?: boolean;
}

interface TdButtonProps
  extends TdButtonBaseProps,
    ComponentPropsWithoutRef<"button"> {}

export function tdButtonClassNames(
  className: TdButtonProps["className"],
  look: TdButtonProps["look"],
  loading: TdButtonProps["loading"],
): string {
  const _className = tdClass("button");
  return classNames(
    _className,
    {
      [_className + "--" + look]: look === "accent" || look === "ghost",
      [_className + "--loading"]: loading,
    },
    className,
  );
}

export default function TdButton({
  className,
  look,
  loading,
  ...props
}: TdButtonProps) {
  return (
    <button
      {...props}
      className={tdButtonClassNames(className, look, loading)}
    />
  );
}
