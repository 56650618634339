import classNames from "classnames";
import React from "react";
import { TXT_subscription } from "../../services/tdSubscription/tdSubscription";
import { tdClassNames } from "../ui/tdClass";
import TdLink from "../ui/TdLink/TdLink";
import {
  ACCOUNT_MANAGE_BILLING_URL,
  ACCOUNT_MANAGE_EMAIL_URL,
  ACCOUNT_MANAGE_PASSWORD_URL,
  CLICK_HERE,
  MAILTO_SUPPORT_URL,
} from "../../services/TdConstants";

const txtDoYouNeed = "Do you need";
const txtDoYouNeedToUpdate = txtDoYouNeed + " to update";
const txtToSendUsAnEmail = "to send us an email";

export default function TdAccountModalFooter() {
  return (
    <ul className={classNames(tdClassNames(["ul"]))}>
      <li>
        <b>{txtDoYouNeed} a receipt?</b>
        <br />
        <TdLink href={ACCOUNT_MANAGE_BILLING_URL} children={CLICK_HERE} /> to
        view and download your invoices and receipts.
      </li>
      <li>
        <b>{txtDoYouNeedToUpdate} your payment details?</b>
        <br />
        <TdLink href={ACCOUNT_MANAGE_BILLING_URL} children={CLICK_HERE} /> to
        view and update your payment methods and billing information.
      </li>
      <li>
        <b>
          Would you like to cancel your {TXT_subscription} or turn off
          auto-renewal?
        </b>
        <br />
        <TdLink href={MAILTO_SUPPORT_URL} children={CLICK_HERE} />{" "}
        {txtToSendUsAnEmail}.
      </li>
      <li>
        <b>{txtDoYouNeedToUpdate} your email address?</b>
        <br />
        <TdLink href={ACCOUNT_MANAGE_EMAIL_URL} children={CLICK_HERE} /> to
        change your email address.
      </li>
      <li>
        <b>{txtDoYouNeedToUpdate} your password?</b>
        <br />
        <TdLink href={ACCOUNT_MANAGE_PASSWORD_URL} children={CLICK_HERE} /> to
        change your password.
      </li>
      <li>
        <b>
          Do you have any questions regarding your account or {TXT_subscription}
          s?
        </b>
        <br />
        <TdLink href={MAILTO_SUPPORT_URL} children={CLICK_HERE} />{" "}
        {txtToSendUsAnEmail}.
      </li>
    </ul>
  );
}
