import { useEffect, useState } from "react";
import classNames from "classnames";

import SvgClose from "../Svg/SvgClose";
import "./tdModal.css";
import { tdClass } from "../tdClass";
import { JSX } from "react";

export type TdModalProps = {
  id: string;
  children: string | JSX.Element | Array<string | JSX.Element>;
  toggle?: number;
  triggerShow?: number;
  triggerHide?: number;
  onShow?: Function;
  onHide?: Function;
  noScroll?: boolean;
};

export const tdModalClassName = tdClass("modal");

export default function TdModal(
  {
    id,
    children,
    toggle,
    triggerShow,
    triggerHide,
    onShow,
    onHide,
    noScroll
  }: TdModalProps
) {
  const [isActive, setIsActive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!!toggle) {
      if (isActive) {
        setIsVisible(false);
      } else {
        setIsActive(true);
      }
    }
  }, [toggle]);

  useEffect(() => {
    if (!!triggerShow && !isVisible) {
      setIsActive(true);
    }
  }, [triggerShow]);

  useEffect(() => {
    if (!!triggerHide && isVisible) {
      setIsVisible(false);
    }
  }, [triggerHide]);

  useEffect(() => {
    if (isActive) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 10);
      return () => clearTimeout(timer);
    }
  }, [isActive]);

  useEffect(() => {
    if (isVisible && typeof onShow === "function") {
      onShow();
    }
    if (!isVisible && typeof onHide === "function") {
      onHide();
    }
    if (!isVisible) {
      const timer = setTimeout(() => {
        setIsActive(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  return (
    <div
      id={id}
      className={classNames(
        tdModalClassName,
        isActive ? tdModalClassName + "--active" : "",
        isVisible ? tdModalClassName + "--show" : "",
        noScroll ? tdModalClassName + "--no-scroll" : ""
      )}
    >
      {isActive && (
        <>
          <div className={tdModalClassName + "-window"}>
            <div
              className={tdModalClassName + "-esc"}
              onClick={() => setIsVisible(false)}
              tabIndex={-1}
            >
              <SvgClose />
            </div>
            <div className={tdModalClassName + "-content"}>{children}</div>
          </div>
          <div
            className={tdModalClassName + "-backdrop"}
            onClick={() => setIsVisible(false)}
          />
        </>
      )}
    </div>
  );
}
