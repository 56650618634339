import { fbAuth } from "../../services/tdFirebase";
import { signOut } from "firebase/auth";

interface TdAuthSignOutProps {
  className?: string;
  onSuccess?: Function;
  children: string | JSX.Element | null | Array<string | JSX.Element | null>;
}

export default function TdAuthSignOut({
  onSuccess,
  ...props
}: TdAuthSignOutProps) {
  const handleSignOut = () => {
    signOut(fbAuth)
      .then(() => {
        // Sign-out successful.
        if (typeof onSuccess === "function") {
          onSuccess();
        }
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return <span {...props} onClick={handleSignOut} />;
}
