import { JSX, useEffect } from "react";

interface TdPageLeaveProps {
  onLeave: (event: Event) => void;
  children?: string | JSX.Element | Array<string | JSX.Element | null> | undefined;
}

const eVisibilityChange = "visibilitychange";
const eMouseLeave = "mouseleave";

export function TdWindowLeave({onLeave, children }: TdPageLeaveProps) {

  useEffect(() => {
    const doc = document;
    const docElement = doc.documentElement;
    const handleEvent = (event: Event) => {
      if(typeof onLeave === "function" && event.type === eVisibilityChange ? doc.hidden : true){
        onLeave(event);
      }
    };
    doc.addEventListener(eVisibilityChange,handleEvent);
    if (docElement) {
      docElement.addEventListener(eMouseLeave, handleEvent);
    }
    return () => {
      doc.removeEventListener(eVisibilityChange, handleEvent);
      if (docElement) {
        docElement.removeEventListener(eMouseLeave, handleEvent);
      }
    };
  }, []);

  return (
    <>
      {children}
    </>
  );
}
