import { httpsCallable } from "firebase/functions";
import { CurrencyCode } from "../tdCurrency";
// import delay from "../delay";
import { fbFunctions } from "../tdFirebase";

interface TdCheckoutRequestSessionPayload {
  data: {
    id: string;
    url: string;
    error?: {
      alias?: string;
      info?: string;
      code: string;
      message?: string;
    };
  };
}

type tdCheckoutRequestSessionUrlParams = {
  priceId: string;
  successUrl: string;
  cancelUrl: string;
  allowPromotionCodes?: boolean;
  promotionCode?: string;
  collectShipping?: boolean;
  promotionItem?: string;
  currency?: CurrencyCode;
  mode?: "payment" | "subscription";
};

export type TdCheckoutRequestSessionUrlResponse = {
  url: string;
  error?: TdCheckoutRequestSessionPayload["data"]["error"];
};

export function tdCheckoutRequestSessionUrl(
  params: tdCheckoutRequestSessionUrlParams,
): Promise<TdCheckoutRequestSessionUrlResponse> {
  const callCountRead = httpsCallable(
    fbFunctions,
    "stripe-requestCheckoutSession_v202406",
  );
  return callCountRead({
    priceId: params.priceId,
    successUrl: params.successUrl,
    cancelUrl: params.cancelUrl,
    allowPromotionCodes: params.allowPromotionCodes ? "yes" : "",
    promotionCode: params.promotionCode || "",
    collectShipping: params.collectShipping ? "yes" : "",
    promotionItem: params.promotionItem || "",
    currencyCode: params.currency || "",
    mode: params.mode || "subscription",
  })
    .then((result) => {
      const response = result as unknown as TdCheckoutRequestSessionPayload;
      console.log("tdCheckoutRequestUrl", response);
      return response && response.data && response.data.url
        ? {
            url: response.data.url,
          }
        : {
            url: "",
            error:
              response && response.data && response.data.error
                ? response.data.error
                : {
                    code: "empty payload",
                  },
          };
    })
    .catch((e) => {
      console.error("tdCheckoutRequestSessionUrl", e);
      return {
        url: "",
        error: {
          code: e.code || "unknown",
          message: e.string || "",
        },
      };
    });
  /*
   */
}
