export type Currency = {
  code: CurrencyCode;
  name: string;
};
export type CurrencyCode = "USD" | "EUR" | "INR" | "JPY";

export const CURRENCY_CODES: CurrencyCode[] = ["USD", "EUR", "INR", "JPY"];
// {
//   code: "USD",
//   name: 'United States Dollar'
// },
// {
//   code: "EUR",
//   name: 'Euro'
// },
// {
//   code: "JPY",
//   name: 'Japanese Yen'
// },
// {
//   code: "INR",
//   name: 'Indian Rupee'
// },

export function isValidCurrencyCode(string: string | undefined): boolean {
  if (!string) {
    return false;
  }
  let isValid = false;
  for (let i = 0; !isValid && i < CURRENCY_CODES.length; i++) {
    if (CURRENCY_CODES[i] === string) {
      isValid = true;
    }
  }
  return isValid;
}

export function validatedCurrencyCode(
  string: string | undefined,
  fallback: CurrencyCode,
): CurrencyCode {
  if (!string) {
    return fallback;
  }
  const normalized = string.trim().toUpperCase();
  return isValidCurrencyCode(normalized)
    ? (normalized as CurrencyCode)
    : fallback;
}
