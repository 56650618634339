import SvgWrapper, { SvgWrapperProps, viewBox960 } from "./SvgWrapper";
import React from "react";

function SvgInfo(props: SvgWrapperProps) {
  return (
    <SvgWrapper {...props} viewBox={viewBox960}>
      <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
    </SvgWrapper>
  );
}
export default SvgInfo;
