import React, { useRef } from "react";
import { createPortal } from "react-dom";
import { SUBSCRIPTIONS_URL } from "../../services/TdConstants";
import { tdDomGetElementById } from "../../services/tdDom/tdDomGetElementById";
import { tdDomGetElementDataAttribute } from "../../services/tdDom/tdDomGetElementDataAttribute";
import {
  getIndividualSubscriptionPlans,
  getStudentSubscriptionPlans,
  TdSubscriptionId,
  TXT_individual,
  TXT_student,
} from "../../services/tdSubscription/tdSubscription";
import { tdClass } from "../ui/tdClass";
import { TdCheckout, TdCheckoutProps, TXT_checkout } from "./TdCheckout";

export interface TdCheckoutPortalProps
  extends Omit<TdCheckoutProps, "plans" | "path"> {
  id?: string;
  path?: string;
  plans?: TdSubscriptionId[];
}

const _renew = "renew";

export function TdCheckoutPortal(props: TdCheckoutPortalProps) {
  const htmlElement = tdDomGetElementById(
    tdClass(props.id || TXT_checkout + "-form"),
  );
  const elRef = useRef<HTMLElement | null>(htmlElement);
  const isRenew = !!tdDomGetElementDataAttribute(htmlElement, _renew);
  const isStudent =
    tdDomGetElementDataAttribute(htmlElement, TXT_checkout) === TXT_student;

  return elRef.current
    ? createPortal(
        <TdCheckout
          isRenew={isRenew}
          isStudent={isStudent}
          path={
            SUBSCRIPTIONS_URL +
            "/" +
            (isStudent
              ? (isRenew ? _renew + "/" : "") + TXT_student
              : isRenew
              ? _renew
              : TXT_individual) +
            "/"
          }
          plans={
            isStudent
              ? getStudentSubscriptionPlans()
              : getIndividualSubscriptionPlans()
          }
          {...props}
        />,
        elRef.current,
      )
    : null;
}
