import { useEffect } from "react";
import { resolveGatedArticleViewCount } from "../../services/tdGatedArticle/tdGatedArticleViewCount";
import { TdAccountSubscriptions } from "../../services/tdSubscription/useTdAccountSubscriptions";
import { TdAuthUid } from "../../services/tdAuth/useTdAuthState";
import { onChangeFunction } from "./TdGatedArticle";

type TdGatedArticleSignedInProps = {
  uid: TdAuthUid;
  subscriptions: TdAccountSubscriptions;
  articleKey: string;
  onChange: onChangeFunction;
};

export default function TdGatedArticleSignedIn({
  uid,
  subscriptions,
  articleKey,
  onChange,
}: TdGatedArticleSignedInProps): null {
  // const [{ active, resolved, watching, checksum }] = useAccountSubscriptions(
  //   uid,
  //   getWebsiteUnlockingSubscriptionPlans(),
  // );
  const { active, resolved, watching, checksum, ready } = subscriptions;
  useEffect(() => {
    // console.log("TdGatedArticleSigned - subscriptions", subscriptions);
    if (ready) {
      if (active.length) {
        // console.log("subscriptions - try active", active.length);
        onChange(true, 0, 0);
      } else {
        // console.log("subscriptions - try active", active.length);
        resolveGatedArticleViewCount(articleKey, uid)
          .then((response) => {
            onChange(
              response ? response.count < response.max : true,
              response ? response.count : 0,
              response ? response.max : 0,
              // "SignedIn-try-active",
            );
          })
          .catch((e) => {
            onChange(
              true,
              0,
              0,
              // "SignedIn-catch"
            );
          });
      }
    }
  }, [active, watching, resolved, checksum, ready]);

  return null;
}
