import "./tdGatedArticle.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { tdDomGetElementById } from "../../services/tdDom/tdDomGetElementById";

import tdUpdateSlot from "../../services/tdSlot";
import { TdAccountSubscriptions } from "../../services/tdSubscription/useTdAccountSubscriptions";
import { tdUpdateClassName } from "../../services/tdUpdateClassName";
import { tdClass } from "../ui/tdClass";
import { TdGatedArticleCounter } from "./TdGatedArticleCounter";
import TdGatedArticleSignedIn from "./TdGatedArticleSignedIn";
import TdGatedArticleSignedOut from "./TdGatedArticleSignedOut";
import type { TdAuthUid } from "../../services/tdAuth/useTdAuthState";

export type TdGatedArticleProps = {
  authUid: TdAuthUid;
  authReady: boolean;
  subscriptions: TdAccountSubscriptions;
  checksum: number;
};

export type onChangeFunction = (
  canRead: boolean,
  count: number,
  max: number,
  trigger?: string, // for debugging
) => void;

const TdGatedBaseClass = tdClass("gated");

const idArticleContainer = TdGatedBaseClass + "-body";
export const TdGatedClassName = TdGatedBaseClass + "--";

export default function TdGatedArticle({
  authUid,
  authReady,
  subscriptions,
}: TdGatedArticleProps) {
  const articleKey = useRef<string>(resolveArticleId());
  const [readCount, setReadCount] = useState<[number, number]>([0, 0]);
  const [canRead, setCanRead] = useState<boolean>(true);
  const callback = useCallback<onChangeFunction>(
    (upCanRead, upCount, upMax, trigger) => {
      setCanRead(upCanRead);
      setReadCount([upCount, upMax]);
    },
    [],
  );

  useEffect(() => {
    tdUpdateSlot(canRead, {
      prefix: TdGatedClassName,
      contentId: "app_story_content",
      placeholderId: "app_story_placeholder",
      slotId: idArticleContainer,
    });
  }, [canRead]);

  useEffect(() => {
    const el = tdDomGetElementById(idArticleContainer);
    if (el) {
      if (authUid) {
        tdUpdateClassName(
          el,
          TdGatedClassName + "out",
          TdGatedClassName + "in",
        );
      } else {
        tdUpdateClassName(
          el,
          TdGatedClassName + "in",
          TdGatedClassName + "out",
        );
      }
    }
  }, [authUid]);

  return articleKey.current ? (
    <>
      {!authReady ? null : authUid ? ( // <>Waiting for auth...</>
        <>
          {/*<p>Is signed in</p>*/}
          <TdGatedArticleSignedIn
            key={articleKey.current}
            uid={authUid}
            subscriptions={subscriptions}
            articleKey={articleKey.current}
            onChange={callback}
          />
        </>
      ) : (
        <>
          {/*<p>Is signed out</p>*/}
          <TdGatedArticleSignedOut
            key={articleKey.current}
            articleKey={articleKey.current}
            onChange={callback}
          />
        </>
      )}
      <TdGatedArticleCounter
        key={articleKey.current}
        uid={authUid}
        canRead={!!canRead}
        count={readCount[0]}
        max={readCount[1]}
      />
    </>
  ) : null;
}

function resolveArticleId() {
  let articleId;
  const slotEl = tdDomGetElementById(idArticleContainer);
  if (slotEl) {
    articleId = (slotEl.getAttribute("data-pid") || "").trim();
  }
  return articleId ? articleId : "";
}
