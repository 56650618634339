import React, { useState } from "react";
import TdModal from "./TdModal";
import type { TdModalProps } from "./TdModal";

interface TdModalDomControllerProps extends TdModalProps {
  id: string;
}
export default function TdModalDomController(props: TdModalDomControllerProps) {
  const [toggle, setToggle] = useState(0);

  const toggleModal = () => {
    setToggle(Date.now());
  };

  return (
    <>
      <input
        id={props.id + "_trigger"}
        type={"checkbox"}
        onChange={toggleModal}
        hidden={true}
      />
      <TdModal toggle={toggle} {...props} />
    </>
  );
}
