import classNames from "classnames";
import "./tdModal.css";
import { tdModalClassName } from "./TdModal";
import { JSX } from "react";

type TdModalFooterProps = {
  children: string | JSX.Element | Array<string | JSX.Element>;
  className?: string;
};

function TdModalFooter({ className, children }: TdModalFooterProps) {
  return (
    <footer
      className={classNames(tdModalClassName + "-footer", className)}
      children={children}
    />
  );
}

export default TdModalFooter;
