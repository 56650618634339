import SvgErrorCircle from "../Svg/SvgErrorCircle";
import TdInfo from "../TdInfo/TdInfo";

type TdFormErrorsProps = {
  message?: string;
  items?: string[];
};

export default function TdFormErrors({ message, items }: TdFormErrorsProps) {
  return (
    <TdInfo look={"error"} icon={<SvgErrorCircle />}>
      {message ? <p>{message}</p> : null}
      {items && items.length ? (
        <ul>
          {items.map((item) => (
            <li>{item}</li>
          ))}
        </ul>
      ) : null}
    </TdInfo>
  );
}
