import { useState } from "react";
import type { ChangeEvent, FormEvent } from "react";
import { LOGIN_RESET_PASSWORD_URL } from "../../services/TdConstants";

import { fbAuth } from "../../services/tdFirebase";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import TdButton from "../ui/TdButton/TdButton";
import TdFormField from "../ui/TdForm/TdFormField";
import type { TdFormFieldProps } from "../ui/TdForm/TdFormField";
import TdLink from "../ui/TdLink/TdLink";

import {
  _Email,
  _email,
  _password,
  _Password,
  getErrorMessage,
  getErrorMessageField,
} from "./tdAuthHelpers";

import TdFormActions from "../ui/TdForm/TdFormActions";
import TdFormFields from "../ui/TdForm/TdFormFields";
import TdForm from "../ui/TdForm/TdForm";
import {
  _errorValueMissing,
  setFieldErrorState,
  calculateFormErrorState,
  getInputEventErrors,
  addFieldErrorState,
  FormErrorState,
  defaultFormErrorState,
} from "../ui/TdForm/tdFormHelpers";
import TdFormErrors from "../ui/TdForm/TdFormErrors";
import classNames from "classnames";

export type TdAuthFormType = "login" | "create";

type TdAuthFormProps = {
  type: TdAuthFormType;
  buttonLabel?: string;
  onSuccess?: Function;
  className?: string;
  children?: string | JSX.Element | null | Array<string | JSX.Element | null>;
  secondaryActions?: JSX.Element | null | Array<JSX.Element | null>;
};

export default function TdAuthForm({
  type,
  buttonLabel,
  onSuccess,
  className,
  children,
  secondaryActions,
}: TdAuthFormProps) {
  const isCreate = type === "create";
  const label = buttonLabel ? buttonLabel : isCreate ? "Sign Up" : "Sign In";
  const defaultValues = {
    [_email]: "",
    [_password]: "",
  };

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<FormErrorState>(defaultFormErrorState());
  const [values, setValues] = useState({ ...defaultValues });
  const [isSent, setIsSent] = useState<boolean>(false);

  const onInput = (event: ChangeEvent<HTMLInputElement>): void => {
    const target = event.currentTarget;
    const name = target.name;
    setValues((prevState) => {
      return {
        ...prevState,
        [name]: target.value,
      };
    });
    setErrors((prevState) =>
      calculateFormErrorState(name, getInputEventErrors(event), prevState),
    );
  };

  const setError = (name: string, error: string): void => {
    setErrors((prevState) => addFieldErrorState(name, error, prevState));
  };

  const callAuth = (email: string, password: string) => {
    if (isCreate) {
      return createUserWithEmailAndPassword(fbAuth, email, password);
    } else {
      return signInWithEmailAndPassword(fbAuth, email, password);
    }
  };

  const submit = () => {
    setIsSent(true);
    if (!values[_email]) {
      setError(_email, _errorValueMissing);
    } else if (!values[_password]) {
      setError(_password, _errorValueMissing);
    } else {
      setIsLoading(true);
      try {
        callAuth(values[_email], values[_password])
          .then(() => {
            // console.log('user', user)
            // setValues({...defaultValues});
            // setErrors({});
            // setIsLoading(false);
            if (typeof onSuccess === "function") {
              onSuccess();
            }
          })
          .catch((error) => {
            const errorInfo = getErrorMessageField(error);
            // console.log('catch error',error);
            // console.log('catch error info',errorInfo)
            setErrors(
              setFieldErrorState(
                errorInfo.field,
                errorInfo.code,
                errorInfo.message,
              ),
            );
            setIsLoading(false);
          });
      } catch (error) {
        console.error("catch error", error);
      }
    }
  };

  const showError = (name: string) => {
    return isSent && errors.fields[name] && errors.fields[name].error
      ? errors.fields[name].error
      : "";
  };

  const fields: TdFormFieldProps[] = [
    {
      name: _email,
      label: _Email,
    },
    // {
    //   name: _emailConfirm,
    //   label: _ConfirmEmail,
    // },
    {
      name: _password,
      label: _Password,
      minLength: isCreate ? 6 : undefined,
      description: isCreate ? (
        "Choose a password with at least 6 characters."
      ) : (
        <TdLink href={LOGIN_RESET_PASSWORD_URL} target={"_blank"}>
          Forgot your password?
        </TdLink>
      ),
      // },{
      //   name: _passwordConfirm,
      //   label: _ConfirmPassword,
    },
  ];

  // const resolveErrorMessage = () => {
  //   let message = "";
  //
  //   fields.forEach((field) => {
  //     if (!message && errors.fields[field.name]) {
  //       message = errors.fields[field.name].error || "";
  //     }
  //   });
  //
  //   if (!message && errors.fields.auth && errors.fields.auth.error) {
  //     message = errors.fields.auth.error;
  //   }
  //   return message ? <TdFormErrors message={message} /> : null;
  // };

  return (
    <TdForm
      onSubmit={(e: FormEvent) => {
        e.preventDefault();
        submit();
      }}
      className={classNames(className)}
    >
      <TdFormFields>
        {fields.map(({ name, ...field }) => {
          return (
            <TdFormField
              key={name}
              name={name}
              {...field}
              type={name}
              value={values[name]}
              required={true}
              hasError={!!showError(name)}
              error={getErrorMessage(showError(name), name)}
              onChange={onInput}
            />
          );
        })}
      </TdFormFields>
      {children ? children : null}
      {errors.hasErrors && errors.fields.auth && errors.fields.auth.error && (
        <TdFormErrors message={errors.fields.auth.error} />
      )}
      <TdFormActions>
        <TdButton type={"submit"} disabled={isLoading} loading={isLoading}>
          {isLoading ? "Processing..." : label}
        </TdButton>
        <>{!!secondaryActions ? secondaryActions : null}</>
      </TdFormActions>
    </TdForm>
  );
}
