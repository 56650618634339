import "./TdCheckoutOption.css";

import React, {
  ChangeEvent,
  ComponentPropsWithoutRef,
  useEffect,
  useState,
} from "react";
import { TXT_Student } from "../../services/tdSubscription/tdSubscription";
import { joinWithDash, joinWithSpace, txtLower } from "../../services/tdText";
import { getErrorMessage } from "../TdAuth/tdAuthHelpers";
import { tdClass } from "../ui/tdClass";
import TdForm from "../ui/TdForm/TdForm";
import TdFormField, { TdFormFieldProps } from "../ui/TdForm/TdFormField";
import TdFormFields from "../ui/TdForm/TdFormFields";
import TdInfo from "../ui/TdInfo/TdInfo";
import TdSection from "../ui/TdSection/TdSection";

export const TdCheckoutPlanClassName = tdClass(
  joinWithDash(["checkout", "plan"]),
);

interface TdCheckoutStudentCheckProps extends ComponentPropsWithoutRef<"div"> {
  setError: Function;
  isSent: boolean;
}

const TXT_inst = "Institution";
const TXT_ofInst = "of your " + txtLower(TXT_inst);

const _name = "name";
const _label = "label";
const _description = "description";

const fields: TdFormFieldProps[] = [
  {
    [_name]: "n",
    [_label]: TXT_Student + " Number",
    [_description]: TXT_Student + " ID, matriculation number, etc.",
  },
  {
    [_name]: _name,
    [_label]: TXT_inst,
    [_description]: joinWithSpace([
      "Name",
      TXT_ofInst,
      " (university, school, etc.)",
    ]),
  },
  {
    [_name]: "d",
    [_label]: "Department",
    [_description]: "Name of department, course, programme, etc.",
  },
  {
    [_name]: "g",
    [_label]: "Graduation Year",
    [_description]: "The expected year of graduation.",
  },
  // {
  //   [_name]: _email,
  //   [_label]: _Email,
  //   [_description]: joinWithSpace([_Email, "address", TXT_ofInst]),
  // },
  {
    [_name]: "p",
    [_label]: "Phone",
    [_description]: joinWithSpace(["Phone", "number", TXT_ofInst]),
  },
  {
    [_name]: "w",
    [_label]: "Website",
    [_description]: joinWithSpace(["Website", TXT_ofInst]),
  },
];

export function TdCheckoutStudentCheck({
  className,
  setError,
  isSent,
  ...props
}: TdCheckoutStudentCheckProps) {
  const [values, setValues] = useState<{ [key: string]: string }>({});

  const checkErrors = () => {
    let hasErrors = 0;
    fields.forEach((f) => {
      hasErrors += !values[f.name] ? 1 : 0;
    });
    setError(hasErrors > 0);
  };
  const onInput = (event: ChangeEvent<HTMLInputElement>): void => {
    const target = event.currentTarget;
    setValues((prevState) => {
      return {
        ...prevState,
        [target.name]: target.value ? "1" : "",
      };
    });
  };

  useEffect(() => {
    checkErrors();
  }, [values]);

  return (
    // <div
    //   className={classNames(
    //     TdCheckoutPlanClassName + "s",
    //     tdClass("flex-row"),
    //     className,
    //   )}
    //   {...props}
    // />
    <TdSection step={3} title={"Your " + TXT_Student + " Information"}>
      <TdInfo>
        Please provide the information below to qualify for a{" "}
        <b>{TXT_Student} discount</b>.
      </TdInfo>
      <TdForm
        // onSubmit={(e: FormEvent) => {
        //   e.preventDefault();
        //   submit();
        // }}
        className={tdClass("mb-0")}
      >
        <TdFormFields>
          {fields.map(({ name, ...field }, i, a) => {
            const showError = isSent && !values[name];
            return (
              <TdFormField
                key={name}
                name={name}
                {...field}
                // type={'name'}
                // value={values[name]}
                required={true}
                hasError={showError}
                error={showError ? getErrorMessage(name, name) : ""}
                onChange={onInput}
                className={a.length - 1 === i ? tdClass("pb-2") : undefined}
              />
            );
          })}
        </TdFormFields>
      </TdForm>
    </TdSection>
  );
}
