import SvgWrapper, { SvgWrapperProps, viewBox960 } from "./SvgWrapper";

function SvgCheck(props: SvgWrapperProps) {
  return (
    <SvgWrapper {...props} viewBox={viewBox960}>
      <path
        // fill={"green"}
        d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"
      />
    </SvgWrapper>
  );
}
export default SvgCheck;
