import "./TdFormActions.css";
import { tdFormClassName } from "./TdForm";
import classNames from "classnames";

type TdFormActionsProps = {
  className?: string;
  children: JSX.Element | null | Array<JSX.Element | null>;
};

export default function TdFormActions({
  className,
  children,
}: TdFormActionsProps) {
  return (
    <div
      className={classNames(tdFormClassName + "-actions", className)}
      children={children}
    />
  );
}
