import { CurrencyCode } from "../tdCurrency";
import { joinWithDash, joinWithSpace, txtLower } from "../tdText";

type TdSubscriptions = {
  "dri-12m": TdSubscription;
  full_12m: TdSubscription;
  web_12m: TdSubscription;
  mag_12m: TdSubscription;
  full_12m_student: TdSubscription;
  web_12m_student: TdSubscription;
  mag_12m_student: TdSubscription;
};

type TdSubscription = {
  alias: string;
  name: string[];
  desc?: string[];
  price: {
    yr: {
      id: string;
      USD: string;
      EUR: string;
      JPY: string;
      INR: string;
    };
    mt: {
      id: string;
      USD: string;
      EUR: string;
      INR: string;
      JPY: string;
    };
  };
};

export type TdSubscriptionPeriod = {
  id: TdSubscriptionBillingPeriod;
  name: string;
  desc: string | null;
  priceId: string;
  amount: string;
};

export type TdSubscriptionId = keyof TdSubscriptions;
export type TdSubscriptionBillingPeriod = keyof TdSubscription["price"];

const isLive = !process.env.REACT_APP_IS_DEV;

export const TXT_Subscription = "Subscription";
export const TXT_subscription = txtLower(TXT_Subscription);

const TXT_AllAccess = "All-Access";
const TXT_Website = "Website";
const TXT_website = txtLower(TXT_Website);
const TXT_Magazine = "Magazine";
const TXT_magazine = txtLower(TXT_Magazine);

export const TXT_Student = "Student";
export const TXT_student = txtLower(TXT_Student);
export const TXT_individual = "individual";

const TXT__12m = "_12m";
const TXT_price = "price";

const TXT_AccessTo = "Access to";
const TXT_the = "the";
const TXT_only = "only";
const TXT_excluding = "excluding";
const TXT_digital = "digital";

const planDri = "dri-12m";
const planAll = ("full" + TXT__12m) as "full_12m";
const planWeb = ("web" + TXT__12m) as "web_12m";
const planMag = ("mag" + TXT__12m) as "mag_12m";
const planAllStudent = (planAll + "_" + TXT_student) as "full_12m_student";
const planWebStudent = (planWeb + "_" + TXT_student) as "web_12m_student";
const planMagStudent = (planMag + "_" + TXT_student) as "mag_12m_student";

function prefixPrice(id: string) {
  return TXT_price + "_" + id;
}

const tdSubscriptionData: TdSubscriptions = {
  // DRI
  [planDri]: {
    alias: "dri",
    name: ["DRI", TXT_Subscription],
    [TXT_price]: {
      yr: {
        id: "",
        USD: "",
        EUR: "",
        JPY: "",
        INR: "",
      },
      mt: {
        id: "",
        USD: "",
        EUR: "",
        JPY: "",
        INR: "",
      },
    },
  },
  // Normal
  [planAll]: {
    alias: "all-access",
    name: [TXT_AllAccess, TXT_Subscription],
    desc: [
      TXT_AccessTo,
      "both",
      TXT_the,
      TXT_website,
      "and",
      TXT_digital,
      TXT_magazine,
    ],
    [TXT_price]: {
      yr: {
        id: isLive
          ? prefixPrice("1PR6rdCFzbB5dEtEzrEQhZna")
          : prefixPrice("1O3DEhCFzbB5dEtEq9SUsXT3"),
        USD: "80",
        EUR: "75",
        JPY: "12,000",
        INR: "5,500",
      },
      mt: {
        id: "",
        USD: "",
        EUR: "",
        JPY: "",
        INR: "",
      },
    },
  },
  [planWeb]: {
    alias: TXT_website,
    name: [TXT_Website, TXT_Subscription],
    desc: [
      TXT_AccessTo,
      TXT_the,
      TXT_website,
      TXT_only,
      "(" + TXT_excluding,
      TXT_the,
      TXT_digital,
      TXT_magazine + ")",
    ],
    [TXT_price]: {
      yr: {
        id: isLive
          ? prefixPrice("1PZpQ0CFzbB5dEtEtAnsXmSm")
          : prefixPrice("1PRPtxCFzbB5dEtErUNuClCv"),
        USD: "64",
        EUR: "60",
        JPY: "10,000",
        INR: "4,400",
      },
      mt: {
        id: "",
        USD: "",
        EUR: "",
        JPY: "",
        INR: "",
      },
    },
  },
  [planMag]: {
    alias: TXT_magazine,
    name: [TXT_Magazine, TXT_Subscription],
    desc: [
      TXT_AccessTo,
      TXT_the,
      TXT_digital,
      TXT_magazine,
      TXT_only,
      "(" + TXT_excluding,
      TXT_the,
      TXT_website + ")",
    ],
    [TXT_price]: {
      yr: {
        id: isLive ? prefixPrice("1PR6DlCFzbB5dEtEiM3J7VVJ") : prefixPrice(""),
        USD: "48",
        EUR: "45",
        JPY: "7,500",
        INR: "3,300",
      },
      mt: {
        id: "",
        USD: "",
        EUR: "",
        JPY: "",
        INR: "",
      },
    },
  },
  [planAllStudent]: {
    alias: joinWithDash(["all-access", TXT_student]),
    name: [TXT_AllAccess, TXT_Student, TXT_Subscription],
    desc: [
      TXT_AccessTo,
      "both",
      TXT_the,
      TXT_website,
      "and",
      TXT_digital,
      TXT_magazine,
    ],
    [TXT_price]: {
      yr: {
        id: isLive ? prefixPrice("1PZ7OJCFzbB5dEtEzYI3SJuj") : prefixPrice(""),
        USD: "60",
        EUR: "55",
        JPY: "9,000",
        INR: "4,125",
      },
      mt: {
        id: "",
        USD: "",
        EUR: "",
        JPY: "",
        INR: "",
      },
    },
  },
  [planWebStudent]: {
    alias: joinWithDash([TXT_website, TXT_student]),
    name: [TXT_Website, TXT_Student, TXT_Subscription],
    desc: [
      TXT_AccessTo,
      TXT_the,
      TXT_website,
      TXT_only,
      "(" + TXT_excluding,
      TXT_the,
      TXT_magazine + ")",
    ],
    [TXT_price]: {
      yr: {
        id: isLive ? prefixPrice("1PZ7WpCFzbB5dEtEzpHsiYic") : prefixPrice(""),
        USD: "48",
        EUR: "44",
        JPY: "7,500",
        INR: "3,300",
      },
      mt: {
        id: "",
        USD: "",
        EUR: "",
        JPY: "",
        INR: "",
      },
    },
  },
  [planMagStudent]: {
    alias: joinWithDash([TXT_magazine, TXT_student]),
    name: [TXT_Magazine, TXT_Student, TXT_Subscription],
    desc: [
      TXT_AccessTo,
      TXT_the,
      TXT_digital,
      TXT_magazine,
      TXT_only,
      "(" + TXT_excluding,
      TXT_the,
      TXT_website + ")",
    ],
    [TXT_price]: {
      yr: {
        id: isLive ? prefixPrice("1PZ7bYCFzbB5dEtE9UFz8nH5") : prefixPrice(""),
        USD: "36",
        EUR: "33",
        JPY: "5,600",
        INR: "2,475",
      },
      mt: {
        id: "",
        USD: "",
        EUR: "",
        JPY: "",
        INR: "",
      },
    },
  },
};

export function getBillingPeriods(): TdSubscriptionBillingPeriod[] {
  return ["yr", "mt"];
}

export function getIndividualSubscriptionPlans(): TdSubscriptionId[] {
  return [planAll, planWeb, planMag];
}

export function getStudentSubscriptionPlans(): TdSubscriptionId[] {
  return [planAllStudent, planWebStudent, planMagStudent];
}

export function getWebsiteUnlockingSubscriptionPlans(): TdSubscriptionId[] {
  return [planDri, planAll, planWeb, planAllStudent, planWebStudent];
}

export function aliasToPlan(
  alias: string,
  plans: TdSubscriptionId[],
): TdSubscriptionId {
  let found: TdSubscriptionId | null = null;
  plans.forEach((plan) => {
    if (tdSubscriptionData[plan].alias === alias) {
      found = plan;
    }
  });
  if (!found) {
    if (plans[0]) {
      found = plans[0];
    } else {
      found = planAll;
    }
  }
  return found;
}

export function planToAlias(plan: TdSubscriptionId): string {
  return tdSubscriptionData[plan]
    ? tdSubscriptionData[plan].alias
    : tdSubscriptionData[planAll].alias;
}

export function getSubscriptionPlans(): TdSubscriptionId[] {
  return Object.keys(tdSubscriptionData) as TdSubscriptionId[];
}

export function getSubscriptionPlanPeriods(
  plan: TdSubscriptionId,
  currency: CurrencyCode,
): TdSubscriptionPeriod[] {
  const planData = tdSubscriptionData[plan];
  const planPrice = planData ? planData[TXT_price] : undefined;
  let periods: TdSubscriptionPeriod[] = [];
  if (planPrice) {
    getBillingPeriods().forEach((period) => {
      if (planPrice[period] && planPrice[period].id) {
        periods.push({
          id: period,
          name: joinWithSpace(planData.name || []),
          desc: planData.desc ? joinWithSpace(planData.desc || []) : null,
          priceId: planPrice[period].id,
          amount: planPrice[period][currency] || "",
        });
      }
    });
  }
  return periods;
}

export function getSubscriptionPlanName(
  plan: TdSubscriptionId,
  short?: boolean,
) {
  return tdSubscriptionData[plan]
    ? joinWithSpace(
        short
          ? tdSubscriptionData[plan].name.concat([]).slice(0, -1)
          : tdSubscriptionData[plan].name,
      )
    : "";
}

export function getSubscriptionPlanPrice(
  plan: TdSubscriptionId,
  period: TdSubscriptionBillingPeriod,
  currency: CurrencyCode,
) {
  return tdSubscriptionData[plan].price[period][currency];
}

export function getSubscriptionPriceId(
  plan: TdSubscriptionId,
  period: TdSubscriptionBillingPeriod,
) {
  return tdSubscriptionData[plan] && tdSubscriptionData[plan][TXT_price][period]
    ? tdSubscriptionData[plan].price[period].id || ""
    : "";
}
