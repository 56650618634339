import { createElement } from "react";
import classNames from "classnames";
import { tdClass } from "../tdClass";
import "./TdLink.css";

type TdLinkProps = {
  children: string | JSX.Element | Array<string | JSX.Element>;
  className?: string;
  href?: string;
  target?: "_blank";
};

export default function TdLink({
  className,
  children,
  href,
  target,
}: TdLinkProps) {
  return createElement(href ? "a" : "span", {
    children: children,
    className: classNames(tdClass("link"), tdClass("nowrap"), className),
    href: href ? href : undefined,
    target: href && target ? target : undefined,
  });
}
