import "./TdFormField.css";
import { tdFormClassName } from "./TdForm";
import type { ComponentPropsWithoutRef } from "react";
import TdFormInput from "./TdFormInput";

export interface TdFormFieldProps extends ComponentPropsWithoutRef<"input"> {
  name: string;
  label: string;
  description?: string | JSX.Element;
  error?: string;
  hasError?: boolean;
  className?: string;
}

function TdFormField({
  label,
  description,
  error,
  hasError,
  className,
  type,
  ...inputProps
}: TdFormFieldProps) {
  const baseClassName = tdFormClassName + "-field";
  let wrapperClassName = baseClassName;
  if (className) {
    wrapperClassName += " " + className;
  }
  if (hasError) {
    wrapperClassName += " " + baseClassName + "--has-errors";
  }

  return (
    <div className={wrapperClassName}>
      <label className={baseClassName + "__label"}>{label}</label>
      <TdFormInput type={type} {...inputProps} />
      {description ? (
        <div className={baseClassName + "__description"}>{description}</div>
      ) : null}
      {hasError ? (
        <div className={baseClassName + "__errors"}>
          <div className={baseClassName + "-error"}>{error}</div>
        </div>
      ) : null}
    </div>
  );
}

export default TdFormField;
