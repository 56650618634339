import "./TdSection.css";
import classNames from "classnames";
import type { ComponentPropsWithoutRef } from "react";
import { tdClass } from "../tdClass";

export interface TdSectionProps extends ComponentPropsWithoutRef<"section"> {
  step?: string | number;
  title?: string;
  className?: string;
}

export const TdSectionClassName = tdClass("section");

export default function TdSection({
  step,
  title,
  className,
  children,
  ...props
}: TdSectionProps) {
  return (
    <section {...props} className={classNames(TdSectionClassName, className)}>
      {(!!step || !!title) && (
        <h2>
          {!!step && <span>{step}</span>}
          {!!title && title}
        </h2>
      )}
      {children}
    </section>
  );
}
