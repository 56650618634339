import "./TdCheckoutOption.css";

import classNames from "classnames";
import React, { ComponentPropsWithoutRef } from "react";
import { joinWithDash } from "../../services/tdText";
import SvgCheck from "../ui/Svg/SvgCheck";
import { tdClass } from "../ui/tdClass";

export const TdCheckoutPlanClassName = tdClass(
  joinWithDash(["checkout", "option"]),
);

interface TdCheckoutOptionsProps extends ComponentPropsWithoutRef<"div"> {}

export function TdCheckoutOptions({ className, ...props }: TdCheckoutOptionsProps) {
  return (
    <div
      className={classNames(
        TdCheckoutPlanClassName + "s",
        tdClass("flex-row"),
        className,
      )}
      {...props}
    />
  );
}

interface TdCheckoutOptionProps extends ComponentPropsWithoutRef<"div"> {
  name: string;
  value: string;
  checked: string;
  onPlan: Function;
  size?: 'sm' | 'lg';
}

export function TdCheckoutOption({
  name,
  value,
  checked,
  onPlan,
  size,
  children,
}: TdCheckoutOptionProps) {
  const id = joinWithDash([TdCheckoutPlanClassName, value]);
  return (
    <div
      className={classNames(
        TdCheckoutPlanClassName,
        size ? TdCheckoutPlanClassName + "--" + size : undefined,
        value === checked ? TdCheckoutPlanClassName + "--checked" : undefined,
      )}
    >
      <input
        id={id}
        type={"radio"}
        name={name}
        value={value}
        defaultChecked={value === checked}
        onChange={(e) => {
          onPlan(value);
        }}
      />
      <label htmlFor={id} className={TdCheckoutPlanClassName + "__label"}>
        {children}
      </label>
      <label htmlFor={id} className={TdCheckoutPlanClassName + "__tick"}>
        <SvgCheck />
      </label>
    </div>
  );
}
