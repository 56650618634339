import classNames from "classnames";
import React, { ComponentPropsWithoutRef, useState } from "react";
import { TD_AUTH_MODAL_ID } from "./TdAuthModal";

interface TdAuthModalTriggerProps {
  className?: string;
  children?: ComponentPropsWithoutRef<"div">['children'];
  onClick?: () => void;
}

export default function TdAuthModalTrigger({ className, children, onClick }: TdAuthModalTriggerProps) {
  return (
    <label
      children={children}
      className={classNames(className)}
      htmlFor={TD_AUTH_MODAL_ID+"_trigger"}
      onClick={()=>{
          if (onClick) onClick();
        }}
    />
  );
}
