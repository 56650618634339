import SvgWrapper from "./SvgWrapper";
import React from "react";

function SvgNewsletter({size}) {
  return <SvgWrapper size={24} style={size?{width: size+'px',height:size+'px'}:undefined}>
    <path
      d="M12 .02c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.99 6.98l-6.99 5.666-6.991-5.666h13.981zm.01 10h-14v-8.505l7 5.673 7-5.672v8.504z"/>
  </SvgWrapper>
}

export default SvgNewsletter;