import type { User } from "firebase/auth";
import { useEffect, useState } from "react";
import { fbAuth } from "../tdFirebase";

export type TdAuthUid = string | null; // yes/no/pending

export interface TdAuthState {
  authKey: number;
  authSignedIn: boolean;
  authUid: TdAuthUid;
  authEmail: string | null;
  authReady: boolean;
}

function composeUserState(user: User | null, isReady: boolean): TdAuthState {
  return {
    authKey: Date.now(),
    authSignedIn: !!(user && user.uid),
    authUid: user && user.uid ? user.uid : null,
    authEmail: user && user.email ? user.email : null,
    authReady: isReady,
  };
}

export default function useTdAuthState() {
  const [authState, setAuthState] = useState<TdAuthState>(
    composeUserState(null, false),
  );
  useEffect(() => {
    return fbAuth.onAuthStateChanged((firebaseUser) => {
      setAuthState(composeUserState(firebaseUser, true));
    });
  }, []);
  return authState;
}
