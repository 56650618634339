import { useRef } from "react";
import { createPortal } from "react-dom";
import { TdAuthUid } from "../../services/tdAuth/useTdAuthState";
import { tdDomGetElementById } from "../../services/tdDom/tdDomGetElementById";
import { tdDomReadHtml } from "../../services/tdDom/tdDomReadHtml";
import { joinWithSpace } from "../../services/tdText";
import { TdGatedClassName } from "./TdGatedArticle";

interface TdGatedArticleCounterProps {
  uid: TdAuthUid;
  canRead: boolean;
  count: number;
  max: number;
  // checksum: number;
}

const id = "tda-gated-counter";

export function TdGatedArticleCounter(props: TdGatedArticleCounterProps) {
  const containerRef = useRef<HTMLElement | null>(tdDomGetElementById(id));
  const contentRef = useRef<string>(tdDomReadHtml(id + "__content"));

  return containerRef.current!
    ? createPortal(
        <TdGatedArticleCounterContent
          {...props}
          content={contentRef.current}
        />,
        containerRef.current,
      )
    : null;
}

interface TdGatedArticleCounterContentProps extends TdGatedArticleCounterProps {
  content: string;
}

function TdGatedArticleCounterContent({
  uid,
  canRead,
  count,
  max,
  content,
}: TdGatedArticleCounterContentProps) {
  const show = canRead && count > 0 && count < max && content.length > 0;
  return show ? (
    <div
      className={joinWithSpace([
        TdGatedClassName + (uid ? "in" : "out"),
        "c-" + count,
      ])}
      dangerouslySetInnerHTML={{
        __html: content
          .replace("{{count}}", "" + count)
          .replace("{{max}}", "" + max),
      }}
    />
  ) : null;
}
