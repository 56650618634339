import React, { useRef } from "react";
import { createPortal } from "react-dom";
import { SUBSCRIPTIONS_URL, TXT_Subscribe } from "../../services/TdConstants";
import { tdDomGetElementById } from "../../services/tdDom/tdDomGetElementById";
import { TdAccountSubscriptions } from "../../services/tdSubscription/useTdAccountSubscriptions";
import { TdAuthState } from "../../services/tdAuth/useTdAuthState";
import { joinWithSpace } from "../../services/tdText";
import { useDebounce } from "../../services/useDebounce";
import SvgUser from "../ui/Svg/SvgUser";

interface TdAccountButtonsProps extends TdAuthState {
  subscriptions: TdAccountSubscriptions;
  checksum: number;
}

export function TdAccountButtonsPortal(props: TdAccountButtonsProps) {
  const elRef = useRef<HTMLElement | null>(tdDomGetElementById("td-top-user"));
  return elRef.current!
    ? createPortal(<TdAccountButtons {...props} />, elRef.current)
    : null;
}

const btnClass = "td-btn";

function TdAccountButtons({
  authUid,
  authReady,
  subscriptions,
}: TdAccountButtonsProps) {
  const show =
    authReady &&
    (!authUid || (subscriptions.ready && !subscriptions.active.length));
  const debouncedShow = useDebounce(show, 900);

  return (
    <>
      {debouncedShow ? (
        <a
          className={joinWithSpace([
            btnClass,
            btnClass + "--red",
            btnClass + "-shop",
          ])}
          href={SUBSCRIPTIONS_URL}
        >
          <span children={TXT_Subscribe} />
        </a>
      ) : null}
      <label
        htmlFor="td-account-modal_trigger"
        className={joinWithSpace([btnClass, btnClass + "--light"])}
      >
        <span>{authUid ? "My Account" : "Sign In"}</span>
        <i>
          <SvgUser />
        </i>
      </label>
    </>
  );
}
