import { onValue, ref } from "firebase/database";
import { fbDatabase } from "../tdFirebase";

const pathPermissions = "/permissions/paid_subscription/";
const keyExpires = "expires";

export function listenToSubscription(
  uid: string,
  plan: string,
  onChange: (
    plan: string,
    exists: boolean,
    isValid: boolean,
    expires: number,
    renews: boolean,
  ) => void,
  full?: boolean,
): Function {
  if (full) {
    return listenToSubscriptionFull(uid, plan, onChange);
  } else {
    return listenToSubscriptionLight(uid, plan, onChange);
  }
}

export function listenToSubscriptionLight(
  uid: string,
  plan: string,
  onChange: (
    plan: string,
    exists: boolean,
    isValid: boolean,
    expires: number,
    renews: false,
  ) => void,
): Function {
  return onValue(
    ref(
      fbDatabase,
      "accounts/" + uid + pathPermissions + plan + "/" + keyExpires,
    ),
    (snapshot) => {
      const now = Date.now();
      const exists = snapshot.exists();
      const expires = exists ? (snapshot.val() as number) : 0;
      onChange(plan, exists, !!(expires && expires > now), expires, false);
    },
    function (error) {
      onChange(plan, false, false, 0, false);
      console.error(error);
    },
  );
}

export function listenToSubscriptionFull(
  uid: string,
  plan: string,
  onChange: (
    plan: string,
    exists: boolean,
    isValid: boolean,
    expires: number,
    renews: boolean,
  ) => void,
): Function {
  return onValue(
    ref(fbDatabase, "accounts/" + uid + pathPermissions + plan),
    (snapshot) => {
      const now = Date.now();
      const exists = snapshot.exists();
      const expires = exists ? (snapshot.child(keyExpires).val() as number) : 0;
      const renews = exists ? (snapshot.child("renews").val() as number) : 0;
      onChange(plan, exists, !!(expires && expires > now), expires, !!renews);
    },
    function (error) {
      onChange(plan, false, false, 0, false);
      console.error(error);
    },
  );
}
