import { httpsCallable } from "firebase/functions";
import { TdAuthUid } from "../tdAuth/useTdAuthState";
import { fbFunctions } from "../tdFirebase";

interface GatedArticleViewCountPayload {
  visitor_id: string;
  max: number;
  count: number;
}

export function resolveGatedArticleViewCount(
  articleKey: string,
  visitorId?: TdAuthUid,
): Promise<GatedArticleViewCountPayload | null> {
  const callCountRead = httpsCallable(fbFunctions, "gated-countView");
  return callCountRead({
    page_id: articleKey,
    visitor_id: visitorId ? visitorId : "n/a",
  })
    .then((result) => {
      return result.data as unknown as GatedArticleViewCountPayload;
    })
    .catch((e) => {
      console.error("gatedArticleViewCount", e);
      return null;
    });
}
