import React from "react";
import { TdAccountButtonsPortal } from "./components/TdAccount/TdAccountButtons";
import { TdAds } from "./components/TdAds";
import TdAuthModal from "./components/TdAuth/TdAuthModal";
import { TdCheckoutPortal } from "./components/TdCheckout/TdCheckoutPortal";
import useTdAuthState from "./services/tdAuth/useTdAuthState";
import TdGatedArticle from "./components/TdGatedArticle/TdGatedArticle";
import "./components/ui/td-ui.css";
import "./App.css";

import { getWebsiteUnlockingSubscriptionPlans } from "./services/tdSubscription/tdSubscription";
import { useTdAccountSubscriptions } from "./services/tdSubscription/useTdAccountSubscriptions";

// // nPuYmSEtBHPa7A8a2Kq9
//
// const isSignedInClass = "td-in";
// const sidebarContentEl = document.getElementById("sidebar-content");

export default function TdApp() {
  // const isOnlineMemo = useRef<boolean>(false);
  const tdAuthState = useTdAuthState();

  // const [inChecked, setInChecked] = useState<boolean>(false);

  // useEffect(() => {
  //   // resolveArticleSlot(true, canRead);
  //   // console.log("tdAuthState", tdAuthState);
  //   // console.log("isOnlineMemo", isOnlineMemo);
  //   if (tdAuthState.authSignedIn && !isOnlineMemo.current) {
  //     tdUpdateClassName(document.documentElement, undefined, isSignedInClass);
  //   } else if (!tdAuthState.authSignedIn && isOnlineMemo.current) {
  //     tdUpdateClassName(document.documentElement, isSignedInClass);
  //   }
  // }, [tdAuthState]);

  const [subscriptions] = useTdAccountSubscriptions(
    tdAuthState.authUid,
    getWebsiteUnlockingSubscriptionPlans(),
  );

  return (
    <>
      <TdAccountButtonsPortal
        {...tdAuthState}
        subscriptions={subscriptions}
        checksum={subscriptions.checksum}
      />
      <TdGatedArticle
        authUid={tdAuthState.authUid}
        authReady={tdAuthState.authReady}
        subscriptions={subscriptions}
        checksum={subscriptions.checksum}
      />

      <TdAuthModal {...tdAuthState} />
      <TdAds
        {...tdAuthState}
        subscriptions={subscriptions}
        checksum={subscriptions.checksum}
      />
      <TdCheckoutPortal {...tdAuthState} subscriptions={subscriptions} />
      {/*<pre>{JSON.stringify(subscriptions, undefined, 2)}</pre>*/}
    </>
  );
}
/*

*/
