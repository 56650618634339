export const CLICK_HERE = "Click here";

const DOMAIN = "thediplomat.com";
const HTTPS = "https://";
const BASE_URL = (process.env.REACT_APP_SITE_URL || HTTPS + DOMAIN);

export const SUBSCRIPTIONS_PATH = "/subscriptions";
export const NEWSLETTER_PATH = "/newsletter";

export const SUBSCRIPTIONS_URL =
  BASE_URL + SUBSCRIPTIONS_PATH;
export const NEWSLETTER_URL =
  BASE_URL + NEWSLETTER_PATH;

export const SUPPORT_EMAIL = "support@" + DOMAIN;
export const MAILTO_SUPPORT_URL = "mailto:" + SUPPORT_EMAIL;

export const ACCOUNT_URL = HTTPS+"account." + DOMAIN;

export const ACCOUNT_MANAGE_SUBSCRIPTIONS_URL =
  ACCOUNT_URL + SUBSCRIPTIONS_PATH;
export const ACCOUNT_MANAGE_BILLING_URL = ACCOUNT_URL; // + "/billing";
export const ACCOUNT_MANAGE_EMAIL_URL = ACCOUNT_URL + "/settings/email";
export const ACCOUNT_MANAGE_PASSWORD_URL = ACCOUNT_URL + "/settings/password";

export const LOGIN_RESET_PASSWORD_URL = ACCOUNT_URL + "/password-reset";

export const TXT_Subscribe = "Subscribe";
