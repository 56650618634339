import { tdDomGetElementById } from "./tdDom/tdDomGetElementById";
import { tdDomReadHtml } from "./tdDom/tdDomReadHtml";
import { tdUpdateClassName } from "./tdUpdateClassName";

type tdSlotConfig = {
  contentId: string;
  placeholderId: string;
  slotId: string;
  prefix?: string;
};

const _lockedClassName = "locked";
const _unlockedClassName = "un" + _lockedClassName;

export default function tdUpdateSlot(
  canRead: boolean,
  config: tdSlotConfig,
): void {
  const slotEl = tdDomGetElementById(config.slotId);
  if (slotEl) {
    slotEl.innerHTML = tdDomReadHtml(
      canRead ? config.contentId : config.placeholderId,
    );
    tdUpdateClassName(
      slotEl,
      (config.prefix || "") + (canRead ? _lockedClassName : _unlockedClassName),
      (config.prefix || "") + (canRead ? _unlockedClassName : _lockedClassName),
    );
    // slotEl.classList.add(canRead ? _unlockedClassName : _lockedClassName);
    // slotEl.classList.remove(canRead ? _lockedClassName : _unlockedClassName);
  }
}
