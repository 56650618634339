import "./TdFormFields.css";
import { tdFormClassName } from "./TdForm";
import classNames from "classnames";

export interface TdFormFieldsProps {
  children: JSX.Element | null | Array<JSX.Element | null>;
  className?: string;
}

export default function TdFormFields({
  className,
  children,
}: TdFormFieldsProps) {
  return (
    <div className={classNames(tdFormClassName + "-fields", className)}>
      {children}
    </div>
  );
}
