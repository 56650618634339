import classNames from "classnames";
import "./tdModal.css";
import { tdModalClassName } from "./TdModal";
import { JSX } from "react";

type TdModalMainProps = {
  className?: string;
  children: string | JSX.Element | null | Array<string | JSX.Element | null>;
};

function TdModalMain({ className, children }: TdModalMainProps) {
  return (
    <section
      className={classNames(tdModalClassName + "-main", className)}
      children={children}
    />
  );
}

export default TdModalMain;
