const _Confirm = "Confirm";
const account = "account";
export const mail = "mail";
export const assword = "assword";

export const _Email = "E" + mail;
export const _Password = "P" + assword;

export const _email = "e" + mail;
export const _emailConfirm = _email + _Confirm;
export const _emailAddress = _email + " address";
export const _password = "p" + assword;
export const _passwordConfirm = _password + _Confirm;

export const _ConfirmEmail = _Confirm + " " + _email;
export const _ConfirmPassword = _Confirm + " " + _password;

export const _errorInvalid = "invalid";
export const _errorUserNotFound = "user-not-found";
export const _errorEmailAlreadyInUse = _email + "-already-in-use";
export const _errorPasswordShort = "short";
export const _errorWrongPassword = "wrong-" + _password;

export const getErrorMessageField = (error: {
  code: string;
  message: string;
}): { field: string; code: string; message: string } => {
  let field = "";
  let code = "";
  let message = "";
  const prefix = "auth/";
  switch (error.code) {
    case prefix + _errorWrongPassword:
      field = _password;
      code = _errorWrongPassword;
      break;
    case prefix + "weak-password":
      field = _password;
      code = _errorPasswordShort;
      break;
    case prefix + _errorEmailAlreadyInUse:
      field = _email;
      code = _errorEmailAlreadyInUse;
      break;
    case prefix + _errorInvalid + "-" + _email:
      field = _email;
      code = _errorInvalid;
      break;
    case prefix + _errorUserNotFound:
      field = _email;
      code = _errorUserNotFound;
      break;
    default:
      field = "auth";
      code = error.code;
      message = error.message;
  }
  return { field: field, code: code, message: message };
};

export const getErrorMessage = (
  errorKey: string,
  fieldName: string
): string => {
  let errorMessage = "";
  switch (errorKey) {
    case _errorEmailAlreadyInUse:
      errorMessage =
        "There is already an " +
        account +
        " with this " +
        _emailAddress +
        ". Please choose another " +
        _emailAddress +
        " or sign in.";
      break;
    case "tooShort":
    case _errorPasswordShort:
      errorMessage =
        "Your " + fieldName + " must be at least 6 characters long.";
      break;
    case "typeMismatch":
    case _errorInvalid:
      errorMessage = "Please enter a valid " + _emailAddress + ".";
      break;
    case "mismatch":
      errorMessage = "The " + fieldName + "s do not match.";
      break;
    case _errorUserNotFound:
      errorMessage =
        "We cannot find an " + account + " with that " + _emailAddress + ".";
      break;
    case _errorWrongPassword:
      errorMessage = "Your " + _password + " is incorrect";
      break;
    default:
      errorMessage = "This field is required.";
      if (fieldName === _emailConfirm || fieldName === _passwordConfirm) {
        errorMessage = "Please retype your " + fieldName + " to confirm.";
      }
  }
  return errorMessage;
};
