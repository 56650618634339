export function joinWithSpace(items: string[]) {
  return items ? items.join(" ") : "";
}

export function joinWithDash(items: string[]) {
  return items.join("-");
}

export function txtLower(string: string) {
  return string ? string.toLowerCase() : "";
}
