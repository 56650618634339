import { SVGProps } from "react";

export interface SvgWrapperProps extends SVGProps<SVGSVGElement> {
  size?: number;
  fill?: string;
  height?: number;
  width?: number;
}

export const viewBox960 = "0 -960 960 960";

function SvgWrapper({
  fill,
  size,
  width,
  height,
  children,
  ...props
}: SvgWrapperProps) {
  const _size = size || 24;
  return (
    <svg
      fill={fill || "currentColor"}
      width={width || _size}
      height={height || _size}
      viewBox={[0, 0, _size, _size].join(" ")}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      children={children}
    />
  );
}

export default SvgWrapper;
