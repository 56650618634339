import "./TdInfo.css";
import classNames from "classnames";
import { ComponentPropsWithoutRef } from "react";
import { tdClass } from "../tdClass";

interface TdInfoProps extends ComponentPropsWithoutRef<"div"> {
  icon?: JSX.Element;
  alignIcon?: "top";
  look?: "alert" | "error" | "white";
  loading?: boolean;
}

export default function TdInfo({
  look,
  icon,
  alignIcon,
  className,
  children,
  ...props
}: TdInfoProps) {
  const baseClassName = tdClass("info");

  return (
    <div
      {...props}
      className={classNames(
        baseClassName,
        look ? baseClassName + "--" + look : "",
        alignIcon ? baseClassName + "--icon-" + alignIcon : "",
        className,
      )}
    >
      {!!icon && <div className={baseClassName + "__icon"}>{icon}</div>}
      <div className={baseClassName + "__body"}>{children}</div>
    </div>
  );
}
