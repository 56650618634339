import React from "react";
import {
  MAILTO_SUPPORT_URL,
  SUBSCRIPTIONS_URL,
  SUPPORT_EMAIL,
  TXT_Subscribe,
} from "../../services/TdConstants";
import {
  getSubscriptionPlanName,
  getSubscriptionPlans,
  TXT_Subscription,
} from "../../services/tdSubscription/tdSubscription";
import { useTdAccountSubscriptions } from "../../services/tdSubscription/useTdAccountSubscriptions";
import TdDate from "../ui/TdDate";
import TdLinkButton from "../ui/TdButton/TdLinkButton";
import { tdClassNames } from "../ui/tdClass";
import TdLink from "../ui/TdLink/TdLink";

export interface TdAccountSubscriptionsProps {
  uid: string | null;
}

export default function TdAccountModalContent({
  uid,
}: TdAccountSubscriptionsProps) {
  const [subscriptions] = useTdAccountSubscriptions(
    uid,
    getSubscriptionPlans(),
    true,
  );

  return (
    <div>
      <div className={tdClassNames(["kicker", "mb-2"])}>
        Active {TXT_Subscription}s
      </div>
      <ul
        className={tdClassNames(["list", "mb-6"])}
        key={subscriptions.checksum}
      >
        {subscriptions.active.length ? (
          subscriptions.active.map((plan) => {
            const planData = subscriptions.data[plan];
            return (
              <li key={plan}>
                <b>{getSubscriptionPlanName(plan)}</b>
                <br />
                {planData.renews ? "Auto-renews on " : "Valid until "}
                <TdDate timestamp={planData.expires} />
              </li>
            );
          })
        ) : (
          <li key={0}>
            <span>No Subscriptions</span>
            <p className={tdClassNames(["text-grey", "m-0"])}>
              Your Diplomat account is not associated with a valid{" "}
              {TXT_Subscription}. This may be because your attempt to subscribe
              was not successful or because your existing {TXT_Subscription} has
              expired or been cancelled at your request. To resolve this, please
              contact us at{" "}
              <TdLink href={MAILTO_SUPPORT_URL} children={SUPPORT_EMAIL} />.
            </p>
          </li>
        )}
      </ul>
      {!subscriptions.active.length && subscriptions.ready ? (
        <TdLinkButton
          href={SUBSCRIPTIONS_URL}
          target={"_blank"}
          className={tdClassNames(["mt-1", "width--full"])}
          children={TXT_Subscribe}
        />
      ) : null}

      {/*<p className={tdClassNames(["my-6"])}>*/}
      {/*  For further information about your account and subscription, click the{" "}*/}
      {/*  <TdLink href={MAILTO_SUPPORT_URL} children={"Manage your Account"} />{" "}*/}
      {/*  button below or feel free to{" "}*/}
      {/*  <TdLink href={MAILTO_SUPPORT_URL} children={"contact us"} />.*/}
      {/*</p>*/}
      {/*<TdInfo>More</TdInfo>*/}
      {/*<pre>{JSON.stringify(subscriptions, undefined, 2)}</pre>*/}
    </div>
  );
}
