import { ChangeEvent, SyntheticEvent } from "react";

export const _hasErrors = "hasErrors";

export const _errorValueMissing = "valueMissing";
export const _errorPatternMismatch = "patternMismatch";
export const _errorTooShort = "tooShort";

type FormErrorStateItems = {
  [code: string]: FormErrorStateItem;
};

type FormErrorStateItem = any;

export type FieldErrorState = {
  valid: boolean;
  errors: FormErrorStateItems;
  error: errorCode;
};

export type FormErrorState = {
  fields: {
    [name: string]: FieldErrorState;
  };
  hasErrors: boolean;
};

type errorCode = string;

export function addFieldErrorState(
  name: string,
  code: errorCode,
  prevState: FormErrorState
) {
  let fieldErrors =
    prevState.fields[name] && prevState.fields[name].errors
      ? { ...prevState.fields[name].errors }
      : {};
  fieldErrors[code] = true;
  return calculateFormErrorState(name, fieldErrors, prevState);
}
export function setFieldErrorState(
  fieldName: string,
  code: errorCode,
  errorMessage: any
): FormErrorState {
  let errors: FormErrorStateItems = {};
  errors[code] = errorMessage || true;
  return calculateFormErrorState(fieldName, errors, defaultFormErrorState());
}

export function defaultFormErrorState(): FormErrorState {
  return {
    fields: {},
    hasErrors: false,
  };
}

export function calculateFormErrorState(
  name: string,
  errors: FormErrorStateItems,
  prevState: FormErrorState
): FormErrorState {
  let _latestError = "";
  Object.keys(errors).forEach((key) => {
    if (errors[key]) {
      _latestError = key;
    }
  });
  let newState = {
    ...prevState,
    [_hasErrors]: false,
  };
  newState.fields[name] = {
    valid: !_latestError,
    errors: errors,
    error: _latestError,
  };
  Object.keys(newState.fields).forEach((key) => {
    if (!newState.fields[key].valid) {
      newState[_hasErrors] = true;
    }
  });
  return newState;
}

export function getInputEventErrors(
  event: ChangeEvent<HTMLInputElement>
): FormErrorStateItems {
  let errors: FormErrorStateItems = {};
  // https://developer.mozilla.org/en-US/docs/web/api/validitystate
  if (event && event.target && event.target.validity) {
    const validity = event.target.validity;
    if (!validity.valid) {
      [
        "badInput",
        "customError",
        _errorPatternMismatch,
        "rangeOverflow",
        "rangeUnderflow",
        "stepMismatch",
        "tooLong",
        _errorTooShort,
        "typeMismatch",
        _errorValueMissing,
      ].forEach((key) => {
        // @ts-ignore
        if (validity[key]) {
          errors[key] = true;
        }
      });
    }
  } else {
    console.error("missing event.target.validity");
  }
  return errors;
}
