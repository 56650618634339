import React from "react";
import TdLink from "../ui/TdLink/TdLink";
import {
  LOGIN_RESET_PASSWORD_URL,
  SUBSCRIPTIONS_URL,
} from "../../services/TdConstants";

export default function TdAuthModalFooterPw() {
  return (
    <>
      <li>
        <b>Forgot your password?</b>
        <br />
        <TdLink href={LOGIN_RESET_PASSWORD_URL}>Click here</TdLink> to reset
        your password.
      </li>
      <li>
        <b>
          Don't have an account with <i>The Diplomat</i> yet?
        </b>
        <br />
        <TdLink href={SUBSCRIPTIONS_URL}>Click here</TdLink> to subscribe.
      </li>
    </>
  );
}
