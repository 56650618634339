import React from "react";
import ReactDOM from "react-dom/client";
import TdApp from "./App";
import { CookiesProvider } from "react-cookie";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

// React.StrictMode
root.render(
  <CookiesProvider
    defaultSetOptions={{
      path: "/",
      maxAge: 3600 * 24 * 300, // 300 days
      // domain: '.thediplomat.com'
    }}
  >
    <TdApp />
  </CookiesProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
