import type { ComponentPropsWithoutRef } from "react";
import { tdClass } from "../tdClass";

interface TdFormProps extends ComponentPropsWithoutRef<"form"> {
  className?: string;
}

export const tdFormClassName = tdClass("form");

export default function TdForm({ className, ...props }: TdFormProps) {
  let wrapperClassName = tdFormClassName;
  if (className) {
    wrapperClassName += " " + className;
  }
  return <form {...props} className={wrapperClassName} />;
}
