import { useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { resolveGatedArticleViewCount } from "../../services/tdGatedArticle/tdGatedArticleViewCount";
import { onChangeFunction } from "./TdGatedArticle";

type TdGatedArticleSignedOutProps = {
  articleKey: string;
  onChange: onChangeFunction;
};

const visitorIdCookie = "td-fp";

export default function TdGatedArticleSignedOut({
  articleKey,
  onChange,
}: TdGatedArticleSignedOutProps): null {
  const calledKey = useRef<string>("");
  const [cookies, setCookie] = useCookies([visitorIdCookie]);
  useEffect(() => {
    if (calledKey.current !== articleKey) {
      calledKey.current = articleKey;
      const storedVisitorId =
        cookies && cookies[visitorIdCookie]
          ? cookies[visitorIdCookie]
          : undefined;
      resolveGatedArticleViewCount(articleKey, storedVisitorId)
        .then((response) => {
          if (response) {
            onChange(
              response.count < response.max,
              response.count,
              response.max,
              // "SignedOut-onChange",
            );
            if (!storedVisitorId && response.visitor_id) {
              setCookie(visitorIdCookie, response.visitor_id);
            }
          } else {
            onChange(
              true,
              0,
              0,
              //"SignedOut-noResponse"
            );
          }
        })
        .catch((e) => {
          onChange(
            true,
            0,
            0,
            // "SignedOut-catch"
          );
        });
    }
  }, [articleKey, onChange]);

  return null;
}
